import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Button, Col, Result, Row, Spin} from 'antd';
import {getJwt} from "../../Helper";
import {getUrls} from "../../Config";

function AuthLink(props) {
    const [urls, setUrls] = useState(getUrls());
    const [progress, setProgress] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Įvyko klaida.");
    const [link, setLink] = useState("#");

    const getAuthLink = async (email) => {
        const response = await axios.post(
            urls.environment.development + urls.payments.authorizationLink,
            email,
            {
                headers: {
                    'Content-Type': "text/plain"
                }
            }
        )
        setLink(response.data.authorizationLink);
        setProgress(false);
    }

    const getUserEmail = async () => {
        const username = localStorage.getItem("username")
        if (username !== null){
            const jwt = getJwt();
            return (
                (await axios.get(
                    urls.environment.development + urls.user.profile,{
                        'headers': {'Authorization': jwt}
                    })).data.email
            );
        }
        return JSON.parse(localStorage.getItem('anonymous-user'))["email"];
    }

    useEffect(() => {
        const fetchData = async () => {
            const email = await getUserEmail();
            await getAuthLink(
                (email)
            );
        }
        fetchData().catch( error => {
            setError(true);
            setErrorMessage(error.message);
            setProgress(false);
        });

    }, []);


    return (
        <>
            <Row style={{
                    maxWidth:"1024px",
                    margin: "0 auto",
                    textAlign: "center"
                }}>
                <Col
                    span={24}
                    style={{padding: 48}}
                >
                    {progress &&
                        <Spin tip="Prašome palaukti" size="large">
                            <div className="content" />
                        </Spin>
                    }

                    {!progress && !error &&
                        <Button
                            type="primary"
                            href={link}
                        >
                            Mokėti
                        </Button>
                    }

                    {error &&
                        <Result
                            status= "500"
                            title= "500"
                            subTitle={errorMessage}
                            extra={<Button href={"#"} type="primary">Bandyti dar kartą</Button>}
                        />
                    }
                </Col>
            </Row>
        </>
    );
}

export default AuthLink;