import React from "react";
import {NavLink, withRouter} from "react-router-dom";

class ImportantToKnowPage extends React.Component{
    render() {
        return (
            <div className={"content"}>
                <div className={'row p-4'}>
                    <div className={'col-12 breadcrumbs mb-4'}>
                        <small>
                            <NavLink className={'text-muted'}
                                     to={'/'}>Titulinis</NavLink>
                            <i className="fa fa-angle-right text-muted"></i>
                            <NavLink className={'text-muted ml-2'}
                                     to={'/important-to-know'}>Kosmetika su kamieninėmis ląstelėmis: ką svarbu žinoti?
                            </NavLink>
                        </small>
                    </div>
                </div>
                <div className={'row p-4'}>
                    <div className={'col-12'}>
                        <h3 className="font-weight-light mb-2">Kosmetika su kamieninėmis ląstelėmis: ką svarbu žinoti?</h3>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Kuo unikalios natūraliai organizme esančios kamieninės ląstelės?</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Kamieninės ląstelės – tai gyvybės pamatas, viso žmogaus organizmo pagrindas. Šios ląstelės palaiko
                            audinių bei viso organizmo stabilią būklę: užtikrina nuolatinį kraujo, odos ląstelių, virškinimo
                            trakto audinių atsinaujinimą, dalyvauja kaulų bei kitų audinių ir organų gijimo procese. Kamieninės
                            ląstelės yra unikalios tuo, kad turi tris kitoms organizmo ląstelėms nebūdingus požymius: yra
                            visų kitų ląstelių pirmtakės, gali ilgą laiką kontroliuojamai daugintis ir atsinaujinti, o esant
                            tam tikroms sąlygoms – virsti bet kuria kita organizmo ląstele.
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Kokią įtaką kamieninės ląstelės daro mūsų odai?</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Mūsų odą sudaro tūkstančiai įvairių ląstelių, kurios nuolat atsinaujina. Šis procesas nevyktų be
                            kamieninių ląstelių. Būtent dėl jų regeneruojasi laiko ar traumos pažeisti odos plotai.
                            Kamieninės ląstelės teigiamai veikia odos būklę, gerina kraujotaką aprūpindamos odą deguonimi ir
                            maisto medžiagomis, slopina uždegiminius procesus. Deja,  senstant, veikiant žalingiems aplinkos
                            veiksniams (oro užterštumui, UV spinduliams), vykstant
                            medžiagų apykaitos procesams, organizme susidaro laisvųjų radikalų, kurie kenkia kamieninėms ląstelėms,
                            tiksliau, jas naikina. Mažėjant natūraliai odoje esančių kamieninių ląstelių kiekiui, lėtėja ir
                            jos atsinaujinimas. Oda ima plonėti, sausėti, atsiranda pirmosios raukšlės, kurios ilgainiui ryškėja.
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>
                            Kokios – žmogaus embriono, gyvūnų ar augalų kamieninės ląstelės naudojamos kosmetikai gaminti?
                        </h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Mokslininkai, tyrinėję kamienines ląsteles, pastebėjo, kad jos išties daro ypatingą poveikį odai.
                            Todėl buvo pradėta gaminti kosmetiką su gyvūnų kamieninėmis ląstelėmis, išgaunamomis iš embrionų,
                            kiaulių placentos. Deja, dėl gyvūnų teisių apsaugos daugelyje pasaulio šalių tokia kosmetika yra
                            uždrausta. Šiandien ji dažniausiai gaminama su augalų kamieninėmis ląstelėmis, kurios veikia kaip
                            biostimuliatorius, skatinantis odą atsinaujinti.
                        </h6>



                        <h4 className={'font-weight-light mt-5 mb-3'}>
                            Ar tai išties panacėja nuo senėjimo, ar tik gamintojų ir prekybininkų reklaminis triukas?
                        </h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Naudojant kosmetikos priemones su augalinėmis kamieninėmis ląstelėmis išties pastebimas
                            visapusiškas odos būklės pagerėjimas. Jai tarsi sugrąžinama antra jaunystė. Paspartėja jos
                            atsinaujinimas, greičiau atkuriamas lipidinis odos barjeras, taip vadinamas epidermis –
                            viršutinė danga, apsauganti tikrąją odą nuo kenksmingo aplinkos poveikio. Epidermis, kuris
                            susideda iš penkių sluoksnių, yra lengviausiai pažeidžiamas. Tad naudojant kosmetiką su
                            kamieninėmis ląstelėmis stiprėja apsauginė odos funkcija. Ji geriau aprūpinama maisto
                            medžiagomis, gerėja jos tonusas, didėja drėgmės kiekis, dėl to mažėja jautrumas ir slopsta
                            uždegimai. Oda tampa skaistesnė, lygesnė ir jaunatviškesnė.
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>
                            Kokia alternatyva kosmetikai su kamieninėmis ląstelėmis? Kokios dar priemonės veiksmingai
                            padeda atjauninti odą?
                        </h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Odos apsauga ir priežiūra sudaro vientisą sistemą. Kad odos senėjimas vyktų kuo lėčiau, būtina
                            naudoti ne tik  drėkinančias, epiderminį barjerą atkuriančias, bet ir odos atsinaujinimą
                            stimuliuojančias kosmetikos priemones. Alternatyva kosmetikai su kamieninėmis ląstelėmis – ta
                            kosmetika, kurioje yra vaisių rūgščių, retinoidų, peptidų ir kitų veikliųjų medžiagų, kurios
                            skatina odos regeneraciją, gerina kraujotaką, aprūpina odą maistingosiomis medžiagomis ir
                            deguonimi.
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>
                            Ar tiesa, kad naktį oda pažeidžiamesnė, tačiau turi savybę ir greičiau atsinaujinti?
                        </h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Dienos metu mūsų oda yra natūraliai apsaugota dėl joje vykstančių procesų – organizmas geba
                            įveikti temperatūros svyravimus, bakterijas, UV spindulius ir kitokį neigiamą aplinkos poveikį.
                            Naktį mūsų organizmas ilsisi, todėl aplinkos dirgikliai, įprasta dienos veikla, stresas,
                            įvairios mintys neslopina būtinų odos medžiagų apykaitos procesų. Todėl miegant ji išties turi
                            savybę greičiau atsinaujinti, jei, žinoma, sudaromos tam tinkamos sąlygos. Svarbiausia – vakare
                            gerai nuvalyti makiažą ir per dieną susikaupusius nešvarumus. Antra – pasitepti veidą naktiniu
                            kremu, kuris padeda sugrąžinti prarastą drėgmę, suteikia būtinų lipidų ir atkuria apsaugines jos
                            savybes. Kadangi senstanti oda atsinaujina lėčiau, naktis – tinkamiausias metas palaikyti tokios
                            odos regeneracijos procesą, stimuliuoti jos natūralias funkcijas specialiomis priemonėmis.
                        </h6>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ImportantToKnowPage)