import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import BreadCrumbIcon from "../helpers/BreadCrumbIcon";

function PurchaseSaleRules(){
    return (
        <div className={"content"}>
            <div className={'row p-4'}>
                <div className={'col-12 breadcrumbs mb-4'}>
                    <small>
                        <BreadCrumbIcon />
                        <NavLink className={'text-muted'}
                                 to={'/'}>Titulinis</NavLink>
                        <i className="fa fa-angle-right text-muted"></i>
                        <NavLink className={'text-muted ml-2'}
                                 to={'/purchase-sale-rules'}>Pirkimo pardavimo taisyklės</NavLink>
                    </small>
                </div>
            </div>
            <div className={'row p-4'}>
                <div className={'col-12'}>
                    <h3 className="font-weight-light mb-2">Prekių pirkimo pardavimo taisyklės</h3>

                    <h4 className={'font-weight-light mt-5 mb-3'}>Sąvokos</h4>
                    <h6 className="font-weight-light text-justify"
                        style={{"line-height":"1.6"}}>
                        <ol>
                            <li>
                                Duomenų valdytojas ir svetainės www.placental.lt operatorius (toliau – Duomenų valdytojas)
                                yra Placental (e-komercijos valdytojas MB „Kodo robotas“, įmonės kodas 305163972, PVM
                                mokėtojo kodas LT100013775414, buveinės adresas Algirdo g. 46-210, LT-03209 Vilnius,
                                Lietuvos Respublika, elektroninio pašto adresas – info@placental.lt.
                            </li>
                            <li>
                                Pirkėjas – veiksnus fizinis arba juridinis asmuo, įsigijęs Prekių interneto parduotuvėje
                                https://www.placental.lt.
                            </li>
                            <li>
                                Šalys – Pirkėjas ir Pardavėjas kartu.
                            </li>
                            <li>
                                Asmens duomenys – bet kuri informacija, susijusi su fiziniu asmeniu – duomenų subjektu,
                                kurio tapatybė yra žinoma arba gali būti tiesiogiai ar netiesiogiai nustatyta
                                pasinaudojant tokiais duomenimis kaip asmens kodas, vienas arba keli asmeniui būdingi
                                fizinio, fiziologinio, psichologinio, ekonominio, kultūrinio ar socialinio pobūdžio
                                požymiai.
                            </li>
                            <li>
                                Grąžinimo forma – nustatyto pavyzdžio forma, kurią užpildžius galima pasinaudoti Prekių
                                atsisakymo teise.
                            </li>
                            <li>
                                Prekė – visa tai, kuo prekiaujama elektroninėje parduotuvėje https://www.placental.lt:
                                kosmetikos ir parfumerijos priemonės, aksesuarai, namų dekoravimo produktai bei dovanų
                                kuponai. Atskirai Taisyklėse vartojamos sąvokos „prekė“, „prekės“ reiškia tiek
                                vienaskaitą, tiek daugiskaitą.
                            </li>
                            <li>
                                Prekių pirkimo pardavimo taisyklės (toliau – Taisyklės) reglamentuoja Pirkėjo ir
                                Pardavėjo teises, įsipareigojimus, Prekių kainas, apmokėjimo tvarką, terminus,
                                pristatymą, Prekių kokybės garantiją, Prekių grąžinimą ir keitimą, atsakomybes.
                            </li>

                            <li>
                                Privatumo politika – Pardavėjo patvirtintas dokumentas, kuriame numatytos pagrindinės
                                Asmens duomenų rinkimo, kaupimo, tvarkymo ir laikymo taisyklės, naudojantis
                                https://www.placental.lt
                            </li>
                        </ol>
                    </h6>

                    <h4 className={'font-weight-light mt-5 mb-3'}>Bendrosios nuostatos</h4>
                    <h6 className="font-weight-light text-justify"
                        style={{"line-height":"1.6"}}>
                        <ol>
                            <li>
                                Šios Prekių pirkimo – pardavimo Taisyklės, jas patvirtinus Pirkėjui (susipažinus su
                                Taisyklėmis bei pažymėjus varnelę prie teiginio “Aš perskaičiau ir sutinku su puslapio
                                sąlygomis ir taisyklėmis”), yra šalims privalomas teisinis dokumentas, kuriame
                                nustatomos Pirkėjo ir Pardavėjo teisės bei pareigos, Prekių įsigijimo bei apmokėjimo už
                                jas sąlygos, Prekių pristatymo ir grąžinimo tvarka, šalių atsakomybė bei kitos su Prekių
                                pirkimu – pardavimu internetinėje parduotuvėje susijusios nuostatos.
                            </li>
                            <li>
                                Pardavėjas pasilieka teisę bet kuriuo metu pakeisti, taisyti ar papildyti Taisykles,
                                atsižvelgdamas į teisės aktų nustatytus reikalavimus.
                            </li>
                            <li>
                                Pirkėjas, patvirtindamas Taisykles, patvirtina, kad jis turi teisę pirkti prekes
                                https://www.placental.lt internetinėje parduotuvėje.
                            </li>
                            <li>
                                Taip pat informuojame, kad šios Taisyklės gali būti keičiamos pasikeitus teisiniam
                                reglamentavimui. Kiekvieną kartą užsakant Prekes, rekomenduojame peržiūrėti Taisykles
                                tam, kad Pirkėjas būtų įsitikinęs, jog pilnai supranta kokiomis sąlygomis, konkrečiu
                                atveju, bus daromas užsakymas.
                            </li>
                            <li>
                                Pirkėjas privalo susipažinti su Pardavėjo patvirtinta Privatumo politika. Sutikimą arba
                                nesutikimą su konkrečiais Pirkėjo Asmens duomenų naudojimo būdais Pirkėjas išreiškia
                                Privatumo politikoje numatyta tvarka.
                            </li>
                            <li>
                                Jei Pardavėjui numatyta teisė ar pareiga pateikti dokumentus ar informaciją Pirkėjui
                                elektroniniu paštu, visais atvejais už veikiančio ir Pirkėjui priklausančio elektroninio
                                pašto adreso pateikimą Pardavėjui atsako pats Pirkėjas.
                            </li>
                        </ol>
                    </h6>

                    <h4 className={'font-weight-light mt-5 mb-3'}>Privatumo politika</h4>
                    <h6 className="font-weight-light text-justify"
                        style={{"line-height":"1.6"}}>
                        <ol>
                            <li>
                                Placental, vykdydama elektroninę Prekyba vadovaujasi Lietuvos Respublikos Asmens duomenų
                                teisinės apsaugos įstatymu, bendruoju duomenų apsaugos reglamentu (ES) 2016/679 ir
                                kitais teisės aktais, nustatančiais Asmens duomenų tvarkymą, laikymąsi ir įgyvendinimą.
                            </li>
                            <li>
                                Privatumo politika yra Taisyklių sudėtinė dalis.
                            </li>
                        </ol>
                    </h6>

                    <h4 className={'font-weight-light mt-5 mb-3'}>Prekės</h4>
                    <h6 className="font-weight-light text-justify"
                        style={{"line-height":"1.6"}}>
                        <ol>
                            <li>
                                Internetinėje parduotuvėje pateikiami Prekių atvaizdai yra iliustracinio pobūdžio.
                                Nepaisant to, kad Pardavėjas dėjo visas pastangas tam, kad Prekių spalvos būtų
                                atvaizduotos kuo tiksliau, Pardavėjas negali garantuoti, kad Pirkėjo įrenginio ekranas
                                tiksliai atspindės Prekių spalvas. Pirkėjas supranta, kad Prekės gali nežymiai skirtis
                                nuo jų atvaizdų.
                            </li>
                            <li>
                                Prekių įpakavimas gali skirtis nuo to, kuris pateikiamas atvaizduose, esančiuose
                                internetinėje parduotuvėje.
                            </li>
                            <li>
                                Visos Prekės, pateikiamos internetinėje parduotuvėje, yra prieinamos Pirkėjui. Tuo
                                atveju, jeigu užsakytos Prekės nebelieka, Pirkėjas yra nedelsiant apie tai informuojamas
                                elektroniniu paštu ar kitomis priemonėmis (skambučiu ir/arba SMS žinute) ir tokios Prekės
                                užsakymo vykdymas yra nutraukiamas.
                            </li>
                            <li>
                                Sutartis tarp Pirkėjo ir Pardavėjo laikoma sudaryta nuo to momento, kai Pirkėjas,
                                išsirinkęs perkamą (-as) Prekę (-es) ir suformavęs Prekių krepšelį, paspaudžia nuorodą
                                “Užsakyti” ir kai Pardavėjas susisiekęs su Pirkėju jo nurodytu telefonu arba el. paštu,
                                patvirtina užsakymą atsiųsdamas laišką el. paštu apie užsakymo patvirtinimą.
                            </li>
                            <li>
                                Kiekviena tarp Pirkėjo ir Pardavėjo sudaryta pirkimo – pardavimo sutartis yra
                                registruojama ir saugoma internetinės parduotuvės duomenų bazėje.
                            </li>
                        </ol>
                    </h6>

                    <h4 className={'font-weight-light mt-5 mb-3'}>Pirkėjo teisės</h4>
                    <h6 className="font-weight-light text-justify"
                        style={{"line-height":"1.6"}}>
                        <ol>
                            <li>
                                Pirkėjas turi teisę susipažinti su savo Asmens duomenimis ir teisę reikalauti ištaisyti
                                neteisingus, neišsamius, netikslius savo Asmens duomenis, parašydamas el. laišką
                                info@placental.lt. Tokiu atveju Pardavėjas nedelsdamas turi įgyvendinti pirkėjo prašymą
                                (per 1-2 darbo dienas)
                            </li>
                            <li>
                                Pirkėjas teise atsisakyti Prekių pirkimo – pardavimo sutarties gali pasinaudoti tik tuo
                                atveju, jeigu Prekė nebuvo sugadinta arba iš esmės nepasikeitė jos išvaizda, taip pat ji
                                nebuvo naudojama ir liko originalioje pakuotėje.
                            </li>
                            <li>
                                Pirkėjas, įsigijęs netinkamos kokybės Prekę internetinėje parduotuvėje, turi teisę
                                reikalauti Pardavėjo kad būtų užtikrinta tinkama prekės kokybė, proporcingai sumažinta
                                prekės kaina, arba vienašališkai nutraukta pirkimo-pardavimo sutartis.
                            </li>
                            <li>
                                Pardavėjas yra atsakingas vartotojui už bet kokią prekių kokybės reikalavimų neatitiktį
                                prekės pristatymo momentu ir paaiškėjusią ne vėliau kaip per dvejus metus nuo prekės
                                pristatymo.
                            </li>
                        </ol>
                    </h6>

                    <h4 className={'font-weight-light mt-5 mb-3'}>Pirkėjo įsipareigojimai</h4>
                    <h6 className="font-weight-light text-justify"
                        style={{"line-height":"1.6"}}>
                        <ol>
                            <li>
                                Pirkėjas privalo sumokėti už Prekes ir priimti jas šių Taisyklių nustatyta tvarka.
                            </li>
                            <li>
                                Jeigu pasikeičia Pirkėjo užsakymo formoje pateikti duomenys, jis privalo nedelsdamas
                                apie juos informuoti Pardavėją.
                            </li>
                            <li>
                                Pirkėjas, naudodamasis com internetine parduotuve, įsipareigoja laikytis šių Taisyklių,
                                kitų sąlygų, aiškiai nurodytų internetinėje parduotuvėje bei nepažeisti Lietuvos
                                Respublikos teisės aktų.
                            </li>
                        </ol>
                    </h6>

                    <h4 className={'font-weight-light mt-5 mb-3'}>Pardavėjo teisės</h4>
                    <h6 className="font-weight-light text-justify"
                        style={{"line-height":"1.6"}}>
                        <ol>
                            <li>
                                Pardavėjas turi teisę savo nuožiūra nustatyti minimalų Prekių krepšelio dydį, t. y.
                                minimalią sumą, kurią pasiekus bus vykdomas Pirkėjo užsakymas. Šios sumos dydis gali
                                būti nurodomas peržiūrint Prekių krepšelį.
                            </li>
                            <li>
                                Jei Pirkėjas bando pakenkti internetinės parduotuvės darbo stabilumui ir saugumui ar
                                pažeidžia savo įsipareigojimus, Pardavėjas turi teisę nedelsiant ir be perspėjimo
                                apriboti ar sustabdyti jam galimybę naudotis internetine parduotuve arba išskirtiniais
                                atvejais panaikinti Pirkėjo registraciją.
                            </li>
                            <li>
                                Susidarius svarbioms aplinkybėms, Pardavėjas gali laikinai arba iš viso nutraukti
                                internetinės parduotuvės veiklą, apie tai iš anksto nepranešę Pirkėjui. Tokiu atveju,
                                visi priimti ir patvirtinti Pirkėjų užsakymai užbaigiami vykdyti, o nauji užsakymai
                                nepriimami.
                            </li>
                            <li>
                                Pardavėjas turi teisę iš anksto nepranešęs Pirkėjui anuliuoti jo užsakymą, jeigu Pirkėjas
                                pasirinkęs apmokėjimą banko pavedimu nesumoka už Prekes.
                            </li>
                            <li>
                                Pardavėjas gali vykdyti įvairaus pobūdžio rinkodaros akcijas ir išankstiniu Pirkėjų
                                sutikimu juos apie šias akcijas informuoti informaciją siunčiant Pirkėjų nurodytais
                                kontaktais. Pardavėjas taip pat turi teisę bet kada vienašališkai, be atskiro įspėjimo
                                keisti aptariamų rinkodaros akcijų sąlygas ir nutraukti jau pradėtas vykdyti akcijas.
                            </li>
                            <li>
                                Pardavėjas įsipareigoja prekes Pirkėjui pristatyti ne vėliau kaip per 30 dienų nuo
                                sutarties sudarymo dienos. Pardavėjas dėl svarbių priežasčių negalėdamas laiku ir
                                tinkamai įvykdyti užsakymo per 30 dienų, įsipareigoja nedelsiant informuoti Pirkėją apie
                                prekių pristatymo vėlavimą.
                            </li>
                        </ol>
                    </h6>

                    <h4 className={'font-weight-light mt-5 mb-3'}>Pardavėjo įsipareigojimai</h4>
                    <h6 className="font-weight-light text-justify"
                        style={{"line-height":"1.6"}}>
                        <ol>
                            <li>
                                Pardavėjas įsipareigoja šiose Taisyklėse ir internetinėje parduotuvėje nustatytomis
                                sąlygomis sudaryti Pirkėjui galimybę naudotis internetinės parduotuvės
                                https://www.placental.lt teikiamomis paslaugomis.
                            </li>
                            <li>
                                Pardavėjas įsipareigoja gerbti Pirkėjo privatumo teisę į jam priklausančią asmeninę
                                informaciją, t. y. Pirkėjo nurodytus Asmens duomenis tvarkyti Lietuvos Respublikos
                                teisės aktų nustatyta tvarka.
                            </li>
                            <li>
                                Pardavėjas įsipareigoja pristatyti Pirkėjo užsakytas Prekes jo nurodytu adresu,
                                nurodytomis sąlygomis.
                            </li>
                            <li>
                                Pardavėjas, dėl svarbių aplinkybių negalėdamas pristatyti Pirkėjui užsakytos Prekės,
                                įsipareigoja pasiūlyti analogišką ar kiek įmanoma savo savybėmis panašesnę Prekę.
                                Pirkėjui atsisakius priimti analogišką ar savo savybėmis panašiausią Prekę, Pardavėjas
                                įsipareigoja grąžinti Pirkėjui sumokėtus pinigus per 7-10 (septynias – dešimt) darbo
                                dienų, jeigu buvo atliktas išankstinis apmokėjimas, o jei mokėjimas nebuvo atliktas –
                                anuliuoti užsakymą.
                            </li>
                            <li>
                                Pirkėjui grąžinant Prekes, Pardavėjas įsipareigoja grąžinti šio sumokėtus pinigus per
                                7-10 (septynias – dešimt) darbo dienų nuo Prekių grąžinimo Pardavėjui dienos.
                            </li>
                        </ol>
                    </h6>

                    <h4 className={'font-weight-light mt-5 mb-3'}>Prekių kainos, apmokėjimo tvarka ir terminai </h4>
                    <h6 className="font-weight-light text-justify"
                        style={{"line-height":"1.6"}}>
                        <ol>
                            <li>
                                Prekių kainos internetinėje parduotuvėje ir suformuotame užsakyme nurodomos eurais su PVM.
                            </li>
                            <li>
                                Pirkėjas atsiskaito už Prekes vienu iš šių būdų:
                            </li>
                            <li>
                                Naudojantis elektronine bankininkyste;
                            </li>
                            <li>
                                Atliekant bankinį pavedimą;
                            </li>
                            <li>
                                Pirkėjas, atsiskaitydamas bankiniu pavedimu, įsipareigoja už Prekes atsiskaityti
                                nedelsdamas. Tik jam sumokėjus bus formuojamas užsakymas.
                            </li>
                            <li>
                                PVM sąskaitose faktūrose pateikiamos pasirinktos Prekės, jų kiekis, suteikiamos
                                nuolaidos, galutinė Prekių kaina, įskaitant visus mokesčius, ir kiti buhalterinę
                                apskaitą reglamentuojančių teisės aktų patvirtinti būtini pateikti duomenys.
                            </li>
                        </ol>
                    </h6>

                    <h4 className={'font-weight-light mt-5 mb-3'}>Prekių pristatymas</h4>
                    <h6 className="font-weight-light text-justify"
                        style={{"line-height":"1.6"}}>
                        <ol>
                            <li>
                                Pirkėjas, užsakydamas Prekes, gali pasirinkti Prekių pateikimo būdą, t. y. pasinaudoti
                                Pardavėjo teikiama Prekių pristatymo paslauga arba Pardavėjo Prekių atsiėmimo punkte.
                            </li>
                        </ol>
                    </h6>

                    <h4 className={'font-weight-light mt-5 mb-3'}>Prekių pristatymas Pirkėjo nurodytu adresu:</h4>
                    <h6 className="font-weight-light text-justify"
                        style={{"line-height":"1.6"}}>
                        <ol>
                            <li>
                                Pirkėjas, užsakymo metu pasirinkęs Prekių pristatymo paslaugą, įsipareigoja nurodyti
                                tikslią Prekių pristatymo vietą.
                            </li>
                            <li>
                                Pirkėjas įsipareigoja Prekes priimti pats. Tuo atveju, kai jis Prekių pats priimti
                                negali, o Prekės pristatytos nurodytu adresu ir remiantis kitais Pirkėjo pateiktais
                                duomenimis, Pirkėjas neturi teisės reikšti Pardavėjui pretenzijų dėl Prekių pristatymo
                                netinkamam subjektui.
                            </li>
                            <li>
                                Prekes pristato Pardavėjas arba jo įgaliotas atstovas.
                            </li>
                            <li>
                                Pristatymo kaina priklauso nuo Pirkėjo pasirinkto pristatymo būdo. Į pristatymo ar
                                produktų kainą nėra įskaičiuoti muito, importo ar kiti galimi taikomi mokesčiai gavėjo
                                šalyje. Už šiuos mokesčius Pardavėjas neatsako.
                            </li>
                            <li>
                                Pardavėjas pasilieka teisę keisti Prekių pristatymo mokestį ir pirkinio sumą, nuo kurios
                                pristatymas nemokamas. Galiojantys įkainiai yra nurodomi užsakymo formavimo lange taip,
                                kad Pirkėjas turi galimybę su jais susipažinti iki patvirtindamas užsakymą.
                            </li>
                            <li>
                                Užsakytas Prekes būtina atsiimti ne vėliau kaip per 21 darbo dieną, skaičiuojamas nuo
                                Pardavėjo patvirtinimo, kad užsakymas jau paruoštas, gavimo momento. Pirkėjui laiku
                                neatsiėmus Prekių, Pardavėjas pasilieka teisę nutraukti užsakymą be įspėjimo ir grąžinti
                                sumokėtą mokėjimą.
                            </li>
                            <li>
                                Prekes gali atsiimti tik užsakymą pateikęs asmuo arba asmuo, nurodytas užsakymo
                                pateikimo metu.
                            </li>
                            <li>
                                Pardavėjas pateikia Prekes Pirkėjui vadovaudamasis Prekių aprašymuose nurodytais
                                terminais. Šie terminai yra preliminarūs, be to netaikomi tais atvejais, kai Pardavėjo
                                sandėlyje nėra reikiamų Prekių, o Pirkėjas informuojamas apie jo užsakytų Prekių trūkumą.
                                Visais atvejais Pardavėjas įsipareigoja Prekes pristatyti ne vėliau kaip per 30 dienų
                                nuo sutarties sudarymo dienos. Esant išimtiniais atvejais Prekių pateikimas gali vėluoti
                                dėl nenumatytų, nuo Pardavėjo nepriklausančių aplinkybių. Tokiu atveju Pardavėjas
                                įsipareigoja nedelsiant susisiekti su Pirkėju ir suderinti Prekių pateikimo sąlygas.
                            </li>
                            <li>
                                Pardavėjas neatsako už Prekių savybių pokyčius, galinčius atsirasti dėl temperatūros
                                pokyčio ar kitų nuo Pardavėjo nepriklausančių aplinkybių poveikio, pristatant Prekes
                                Pirkėju. Pardavėjas taip pat neatsako už transportavimo metu padarytą žalą.
                            </li>
                            <li>
                                Tais atvejais, kai vartojimo pirkimo–pardavimo sutartyje numatytas daiktų gabenimas ir
                                Pardavėjas išsiunčia daiktus Pirkėjui, daiktų atsitiktinio žuvimo ar sugedimo rizika
                                pereina Pirkėjui, kai jis ar jo nurodytas asmuo, išskyrus vežėją, priima daiktus. Jeigu
                                daiktai perduodami vežėjui, kurį pasirinko Pirkėjas, o Pardavėjas nesiūlė tokio
                                pristatymo, šioje dalyje numatyta rizika pereina Pirkėjui, kai daiktai perduodami
                                vežėjui.
                            </li>
                            <li>
                                Visais atvejais Pardavėjas atleidžiamas nuo atsakomybės už Prekių pateikimo terminų
                                pažeidimą, jeigu Prekės Pirkėjui nėra pateikiamos arba pateikiamos ne laiku dėl Pirkėjo
                                kaltės arba dėl nuo Pirkėjo priklausančių aplinkybių.
                            </li>
                            <li>
                                Prekių pateikimo Pirkėjui metu Pirkėjas privalo kartu su Pardavėju arba jo įgaliotu
                                atstovu patikrinti siuntos ir Prekės(-ių) būklę bei pasirašyti siuntos perdavimo –
                                priėmimo dokumentą. Pirkėjui pasirašius siuntos perdavimo – priėmimo dokumente, laikoma,
                                kad siunta yra perduota tinkamos būklės, Prekių pažeidimų, kurių atsiradimo pagrindas
                                priskirtinas ne gamykliniam brokui, bei Prekės(-ių) komplektacijos neatitikimų (tokių,
                                kuriuos galima nustatyti išorinės Prekių apžiūros metu) nėra. Pastebėjęs, kad pateiktos
                                siuntos pakuotė pažeista (suglamžyta, šlapia ar kitaip išoriškai pažeista), Prekė(-ės)
                                yra pažeista(-os) ir/ar Prekė(-ės) yra netinkamos komplektacijos, Pirkėjas privalo tai
                                pažymėti siuntos perdavimo – priėmimo dokumente.
                            </li>
                        </ol>
                    </h6>

                    <h4 className={'font-weight-light mt-5 mb-3'}>Prekių grąžinimas ir keitimas</h4>
                    <h6 className="font-weight-light text-justify"
                        style={{"line-height":"1.6"}}>
                        <ol>
                            <li>
                                Placental prekės, kurios vartotojui parduotos pagal vartojimo pirkimo–pardavimo
                                nuotolines ar ne prekybos patalpose sudarytas sutartis, grąžinamos Civilinio kodekso
                                6.228(10) ir 6.228(11) straipsniuose nustatyta tvarka.
                            </li>
                            <li>
                                Norėdamas grąžinti kokybišką Prekę(-es), Pirkėjas apie tai Pardavėjui turi pranešti
                                raštu (el. paštu) bei užpildyti Prekių gražinimo formą, kurią galima rasti com skiltyje
                                „Prekių grąžinimo forma“. Jei Pirkėjas, įsigydamas prekę, pasinaudojo galimybe atsiimti
                                Placental fizinėje parduotuvėje, jis gali pats pristatyti grąžinamą prekę į tą pačią
                                parduotuvę, tos parduotuvės darbo valandomis.
                            </li>
                            <li>
                                Pirkėjas turi teisę per 14 dienų nuo prekių pristatymo arba atsiėmimo dienos grąžinti
                                Prekes, kurių prekinė išvaizda ir (ar) pakuotė yra nepažeista, prieš tai raštu
                                informavęs Pardavėją.
                            </li>
                            <li>
                                Pardavėjas turi teisę atsisakyti priimti Prekes, kurios buvo išpakuotos po pristatymo
                                ir yra netinkamos grąžinti dėl sveikatos apsaugos ar higienos priežasčių, arba Prekės
                                negali būti grąžintos kitais Lietuvos Respublikos teisės aktų nustatytais atvejais.
                                Pirkėjas privalo užtikrinti, kad grąžinamų Prekių kokybė, pakuotė ir atitikimas saugumo
                                ir higienos reikalavimams nepasikeistų.
                            </li>
                            <li>
                                Pardavėjas turi teisę atsisakyti priimti Pirkėjo grąžinamas ar keičiamas Prekes šių
                                Taisyklių 58 punkte numatytais atvejais, taip pat, jei nustatoma, kad Prekės pažeistos,
                                nuimtos ir/ arba pažeistos etiketės, nuplėšta apsauginė plėvelė, sugadinta pakuotė ir
                                kt. ir/ arba Prekės buvo naudojamos.
                            </li>
                            <li>
                                Pasinaudojus Prekių grąžinimo teise, išlaidas už Prekių grąžinimą apmoka Pirkėjas.
                            </li>
                            <li>
                                Pardavėjas nėra atsakingas už Prekių kokybės pablogėjimą, jei Pirkėjas ar asmenys,
                                kuriems Pirkėjas perdavė Prekes, jas naudojo ne tiems tikslams, kuriems tokios Prekės
                                yra paprastai naudojamos, nesilaikė instrukcijose nurodytų reikalavimų, pažeidė Prekių
                                gabenimo, laikymo, naudojimo ir/ar sandėliavimo taisykles, taip pat, jeigu matomi Prekių
                                įpakavimo ir kiti išoriniai defektai, raštu neaptarti Prekių perdavimo metu, ar Prekių
                                kokybės pablogėjimas yra dėl Pirkėjo ar kitų asmenų, kuriems Pirkėjas perdavė Prekes,
                                veiksmų.
                            </li>
                            <li>
                                Pinigai už grąžintas Prekes visais atvejais pervedami mokėjimo pavedimu ir tik į
                                mokėtojo banko sąskaitą.
                            </li>
                        </ol>
                    </h6>

                    <h4 className={'font-weight-light mt-5 mb-3'}>Atsakomybė</h4>
                    <h6 className="font-weight-light text-justify"
                        style={{"line-height":"1.6"}}>
                        <ol>
                            <li>
                                Pirkėjas yra visiškai atsakingas už jo pateikiamų Asmens duomenų teisingumą. Jei Pirkėjas
                                nepateikia tikslių Asmens duomenų, Pardavėjas neatsako už dėl to atsiradusius padarinius
                                ir įgyja teisę reikalauti iš Pirkėjo patirtų tiesioginių nuostolių atlyginimo.
                            </li>
                            <li>
                                Pirkėjas atsako už veiksmus, atliktus naudojantis šia internetine parduotuve.
                            </li>
                            <li>
                                Užsiregistravęs Pirkėjas atsako už savo prisijungimo duomenų perdavimą tretiesiems
                                asmenims. Jei MB „com“ teikiamomis paslaugomis naudojasi trečiasis asmuo, prisijungęs
                                prie internetinės parduotuvės naudodamasis Pirkėjo prisijungimo duomenimis, Pardavėjas
                                šį asmenį laiko Pirkėju.
                            </li>
                            <li>
                                Pardavėjas atleidžiamas nuo bet kokios atsakomybės tais atvejais, kai nuostoliai kyla
                                dėl to, jog Pirkėjas, neatsižvelgdamas į Pardavėjo rekomendacijas ir savo
                                įsipareigojimus, nesusipažino su šiomis Taisyklėmis, nors tokia galimybė jam buvo
                                suteikta.
                            </li>
                            <li>
                                Jei Pardavėjo internetinėje parduotuvėje yra nuorodos į kitų įmonių, įstaigų,
                                organizacijų ar asmenų tinklapius, Pardavėjas nėra atsakingas už ten esančią informaciją
                                ar vykdomą veiklą, tų tinklapių neprižiūri, nekontroliuoja ir toms įmonėms bei asmenims
                                neatstovauja.
                            </li>
                        </ol>
                    </h6>

                    <h4 className={'font-weight-light mt-5 mb-3'}>Apsikeitimas informacija</h4>
                    <h6 className="font-weight-light text-justify"
                        style={{"line-height":"1.6"}}>
                        <ol>
                            <li>
                                Pardavėjas visus pranešimus siunčia Taisyklėse numatyta tvarka Pirkėjo pateiktu
                                elektroninio pašto adresu.
                            </li>
                            <li>
                                Pirkėjas visus pranešimus ir klausimus siunčia Pardavėjo internetinėje parduotuvėje
                                nurodytomis susisiekimo priemonėmis.
                            </li>
                            <li>
                                Taisyklėse vartojama sąvoka „raštu“ apima ir elektroninius laiškus.
                            </li>
                        </ol>
                    </h6>

                    <h4 className={'font-weight-light mt-5 mb-3'}>Baigiamosios nuostatos</h4>
                    <h6 className="font-weight-light text-justify"
                        style={{"line-height":"1.6"}}>
                        <ol>
                            <li>
                                Šios Taisyklės sudarytos vadovaujantis LR teisės aktais.
                            </li>
                            <li>
                                Šių Taisyklių pagrindu kylantiems santykiams taikoma LR teisė.
                            </li>
                            <li>
                                Visi nesutarimai, kilę dėl šių Taisyklių vykdymo, sprendžiami derybų būdu. Nepavykus
                                susitarti, ginčai sprendžiami LR įstatymų nustatyta tvarka, pagal Pardavėjo buveinės
                                vietą.
                            </li>
                            <li>
                                Pirkėjas neturi teisės perleisti ar perduoti visų ar dalies teisių ir įsipareigojimų,
                                kylančių iš šių Taisyklių, trečiajam asmeniui ar asmenims be Pardavėjo rašytinio
                                sutikimo.
                            </li>
                            <li>
                                Tuo atveju, jei Pirkėjas nesutinka su Pardavėjo parengtu ir Placental perduotu
                                atsakymu į Pirkėjo rašytinę pretenziją, savo prašymą/skundą dėl Placental įsigytos
                                Prekės iš Pardavėjo Pirkėjas (fizinis asmuo, vartotojas) gali pateikti Valstybinei
                                vartotojų teisių apsaugos tarnybai (Vilniaus g. 25, 01402 Vilnius, el.p. tarnyba@vvtat.lt,
                                tel. 85 262 67 51, faks. (85) 279 1466, interneto svetainėje www.vvtat.lt (taip pat
                                Valstybinės vartotojų teisių apsaugos tarnybos teritoriniams padaliniams apskrityse) –
                                ar užpildyti prašymo formą EGS platformoje https://ec.europa.eu/odr/.
                            </li>
                        </ol>
                    </h6>

                </div>
            </div>
        </div>
    )
}

export default withRouter(PurchaseSaleRules)