export const productData = [
    {
        "cream": {
            "name": "Face Cream",
            "price": 119
        },
        "serum": {
            "name": "Face Serum",
            "price": 139
        },
        "exosomes":{
            "name": "Exosomes Serum",
            "price": 199
        },
        "set": {
            "name": "Face Cream and Serum",
            "price": 199
        },
        "setExosomesCreamSerum": {
            "name": "Exosomes & Cream & Serum",
            "price": 349
        },
        "setExosomesCream": {
            "name": "Exosomes & Cream",
            "price": 259
        },
        "setExosomesSerum": {
            "name": "Exosomes & Serum",
            "price": 279
        },
    },
];