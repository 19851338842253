import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import BreadCrumbIcon from "../helpers/BreadCrumbIcon";

class AboutChanges extends React.Component{
    render() {
        return (
            <div className={"content"}>
                <div className={'row p-4'}>
                    <div className={'col-12 mb-4'}>
                        <small>
                            <BreadCrumbIcon />
                            <NavLink className={'text-muted'} to={'/'}>Titulinis</NavLink>
                            <i className="fa fa-angle-right text-muted"></i>
                            <NavLink className={'text-muted ml-2'}
                                     to={'/changes'}>Kas pasikeitė?</NavLink>
                        </small>
                    </div>
                </div>
                <div className={'row p-4'}>
                    <div className={'col-12'}>
                        <h3 className="font-weight-light mb-2">Kas pasikeitė?</h3>

                        <h4 className={'font-weight-light mt-5 mb-3'}>
                            Kas pasikeitė lyginant su ankstesne kremo serija?
                        </h4>
                        <h6 className="font-weight-light text-justify" style={{"line-height":"1.6"}}>
                            Visų pirma – atnaujintas ir tvaresnis dizainas. Šiuo metu Jums siūlome kokybiškesnio
                            plastiko kremo buteliukus, įpakuotus stilingose, minimalistinėse baltos ir juodos spalvos
                            pakuotėse. Pastarosios yra riboto leidimo ir prekyboje bus pasiekiamos tik švenčių metu arba
                            užsakomos papildomai siekiant dovanoti Placental kremą kaip dovaną ypatingomis progomis.
                            <br/><br/>Naujieji kremo buteliukai pasižymi ne tik tvaresnėmis plastiko savybėmis jį
                            perdirbant, bet ir yra gerokai tinkamesni siekiant išsaugoti medicinines kremo savybes
                            laikant jį taroje. Tobulinome ne tik kremo buteliukus ir pakuočių dizainą, bet atsižvelgdami
                            į klientų atsiliepimus, koregavome ir kremo faktūrą bei kvapą.<br/><br/> Tikime, kad šį kartą
                            Placental kremai maloniai nustebins itin drėkinančios naujos formulės lengva konsistencija bei švelniu
                            kvapu. Svarbu ir tai, kad labaratorijose buvo tobulinamos kremo sudedamosios dalys siekiant
                            dar geresnio efekto. Daugiau apie naujas kremo savybes skaityjite skiltyje:
                            <NavLink to={'/cream-composition'}><h6 className={'font-weight-light d-inline-block'}> "Placental
                            kremo sudėtis"</h6></NavLink>
                        </h6>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(AboutChanges)