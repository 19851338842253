import React from "react";
import {getUrls} from "../Config";
import axios from "axios";

class PasswordResetConfirm extends React.Component{
    constructor(props){
        super(props);
        this.state={
            error: false,
            errorMsg: null,
            message: null,
            success: false,
            inProgress: false,
            urls: getUrls(),
            token: false
        };
    }

    getToken = () => {
        let tokenObj = new URLSearchParams(this.props.location.search);
        let token = tokenObj.get('token');
        let result = token ? token : false;

        if (!result){
            this.setState({
                success: true,
                message: "Sveiki atvykę į Placental puslapį!",
                inProgress: false,
                error: false
            });
        }
        this.setState({
            token: result
        });
    }


    submit = (e) => {
        e.preventDefault();

        if (this.state.token){
            this.setState({
                inProgress: true,
                message: "Prašome palaukti ...",
                error: false
            });

            axios.get(this.state.urls.environment.development + this.state.urls.passwordResetConfirmation + this.state.token).then(
                (result) => {
                    if (!result.data){
                        this.setState({
                            success: false,
                            message: "Infromacija nerasta. Bandykite dar kartą.",
                            inProgress: false,
                            error: false
                        });
                    }else{
                        this.setState({
                            success: true,
                            message: "Dėkojame! Jums išsiųstas el. laiskas su nauju slaptažodžiu.",
                            inProgress: false,
                            error: false
                        });
                    }
                }
            ).catch(
                (err) => {
                    this.setState({
                        inProgress: false,
                        error: true,
                        message: null,
                        errorMsg: "Įvyko klaida. Mėginkite dar kartą."
                    })
                }
            );
        }else{
            this.setState({
                inProgress: false,
                error: true,
                message: null,
                errorMsg: "Informacija nerasta. Bandykite dar karta arba susisiekite su administratoriumi."
            })
        };
    };

    componentDidMount() {
        this.getToken();
    }

    render() {
        return (
            <div className={"p-4"}>
                <div className="row">
                    <div className="col-12">
                        <h3 className="font-weight-light mb-4">Slaptažodžio atkūrimas</h3>
                        <p>{this.state.message}
                            <fa className={this.state.inProgress ? 'fa fa-sync fa-spin' : 'd-none'}></fa>
                        </p>
                    </div>
                </div>
                <div className={!this.state.success ? 'row p-4' : 'd-none'}>
                        <button type={"submit"}
                                onClick={this.submit}
                                className="btn btn-warning">Atkurti slaptažodį</button>
                </div>
                <h1 className={this.state.success ? 'text-success' : 'd-none'}>
                    <fa className="fa fa-check-circle"></fa>
                </h1>

                <small className={"text-danger"}>{this.state.errorMsg}</small>
                <h1 className={this.state.error ? 'text-danger' : 'd-none'}>
                    <fa className="fa fa-exclamation-circle"></fa>
                </h1>
            </div>
        )
    }
}

export default PasswordResetConfirm