import React from "react";
import BreadCrumbIcon from "./helpers/BreadCrumbIcon";
import {NavLink} from "react-router-dom";

class Delivery extends React.Component{
    render() {
        return (
            <div className={"content"}>
                <div className={'row p-4'}>
                    <div className={'col-12 breadcrumbs mb-4'}>
                        <small>
                            <BreadCrumbIcon />
                            <NavLink className={'text-muted'}
                                     to={'/'}>Titulinis</NavLink>
                            <i className="fa fa-angle-right text-muted"></i>
                            <NavLink className={'text-muted ml-2'}
                                     to={'/delivery'}>Prekių pristatymas</NavLink>
                        </small>
                    </div>
                </div>
                <div className="row p-4">
                    <div className="col-12">
                        <h3 className="font-weight-light mb-2">Prekių pristatymas</h3>

                        <h4 className="font-weight-light mb-3 mt-5 text-dark">Bendra informacija</h4>

                        <h6 className="font-weight-light"
                            style={{"line-height":"1.6"}}>Prekių pristatymas
                            NEMOKAMAS. Pristatymo laikas Lietuvoje 1-2 d.d., kitose šalyse pristatymas vykdomas pagal
                            pasirinktos šalies pateikiamus terminus. Ši paslauga turi išsamią sekimo informaciją, todėl JUS
                            siunta pasieks greitai ir saugiai. Puikiai tinka dovanoms, kurios turi laiku pasiekti gavėją.
                            Prieš pristatant siuntą (dieną prieš ir pristatymo dieną) kurjeris atsiunčia SMS žinutę.</h6>

                        <h4 className="font-weight-light mb-3 mt-5">Partneriai</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>Dirbame tik su
                            patikimomis kurjerių tarnybomis, todėl atrinkome geriausius Lietuvos ir užsienio partnerius.</h6>

                        <h4 className="font-weight-light mb-3 mt-5">Prekės sekimas</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>Atlikus apmokėjimą už
                            Placental kremą Jums bus išsiųstas patvirtinamasis el. laiškas, jame bus nurodytas prekės sekimo
                            kodas. Su šiuo kodu galite stebėti prekės kelią.</h6>
                    </div>
                </div>
            </div>
        )
    }
}

export default Delivery