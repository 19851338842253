import React from "react";
import {withRouter} from "react-router-dom";
import placental_logo_secondary from "../../images/placental_bg_gray.png";

class BreadCrumbIcon extends React.Component{
    render() {
        return (
            <img className={'mr-2'}
                 src={placental_logo_secondary}
                 alt={'Placental logo'}
                 style={{width: '18px', height: '18px', marginTop: '-3px'}}
            />
        )
    }
}

export default withRouter(BreadCrumbIcon)