import React from "react";
import {getUrls} from "../Config";
import {getJwt, infoMessageToast, authenticate} from '../Helper';
import axios from "axios";
import {Redirect} from "react-router-dom";

class AdminOrders extends React.Component{
    constructor(props){
        super(props);
        this.state= {
            id: null,
            orders: [],
            order: {},
            user: {},
            product: {},
            urls: getUrls(),
            admin: true,
            offset: 0,
            pageSize: 10,
            paginationNavigationSize: 10,
            loading: true,
            paginationLoading: false,
            orderIdToDelete: null,
            orderModal:false,
            paging: {
                first: true,
                last: false,
                empty: false,
                numberOfElements: null,
                totalElements: null,
                totalPages: null,
                pageable:{
                    offset: null,
                    pageNumber: null
                }
            }
        };
        this.handleChange = this.handleChange.bind(this);
    }

    mapOrderStatus = (status) => {
        let object = {class: "", name: ""};
        let pending = "Laukiama";
        let accepted = "Priimta";
        let dispatched = "Išsiųsta";
        let delivered = "Pristatyta";
        let canceled = "Atmesta";
        let classPrefix = "plc-badge-";
        let classPending = classPrefix + "pending";
        let classAccepted = classPrefix + "accepted";
        let classDispatched = classPrefix + "dispatched";
        let classDelivered = classPrefix + "delivered";
        let classCanceled = classPrefix + "canceled";
        switch(status) {
            case "1":
                object.name = pending;
                object.class = classPending;
                break;
            case "2":
                object.name = accepted;
                object.class = classAccepted;
                break;
            case "3":
                object.name = dispatched;
                object.class = classDispatched;
                break;
            case "4":
                object.name = delivered;
                object.class = classDelivered;
                break;
            case "5":
                object.name = canceled;
                object.class = classCanceled;
                break;
            default:
                object.name = pending;
                object.class = classPending;
        }
        return object;
    };

    handleChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    checkAdminAccess = () => {
        let role = localStorage.getItem("role");
        if (role !== "ROLE_ADMIN") {
            this.setState({admin: false});
        }
    }

    getOrderInfo = async (orderNumber) => {
        let jwt = getJwt();

        await axios.get(this.state.urls.environment.development +
            this.state.urls.apiVersion +
            this.state.urls.order.order +
            orderNumber, {
            'headers': {
                'Authorization': jwt
            }
        }).then((response) => {
            this.setState(
                {
                    order: response.data
                }
            );

        }).catch(
            (error) => {
                console.log(error);
            }
        );
    }

    pagination = () => {
        let pages = [];
        let totalPages = this.state.paging.totalPages;
        let pageSize = this.state.pageSize;
        let pageNumber = this.state.paging.pageable.pageNumber;

        for (let i = 0; i < totalPages; i++) {
            pages.push(i)
        }

        let getOrdersWithPagination = (pageNo, pageSize) => this.getOrders(pageNo, pageSize);

        let pagination =  pages.map(function(page){
            return(
                <span role="button" onClick={()=>getOrdersWithPagination(page, pageSize)}
                      className={(page === pageNumber ? 'badge badge-primary mr-2' : 'mr-2')}>{page + 1}
                </span>
            )
        })

        pagination.unshift(<span role="button" onClick={()=>getOrdersWithPagination(0, pageSize)}
                                 className={'mr-2'}>&#60;</span>)

        pagination.push(<span role="button"
                              onClick={()=>getOrdersWithPagination(pages[pages.length - 1],
                                  pageSize)}>&#62;</span>)

        return (
            <div className={"text-muted"}>
                {pagination} &nbsp;<span className={
                    this.state.paginationLoading === true ?
                        'fa fa-lg fa-sync fa-spin' :
                        'd-none'}></span>
            </div>
        )
    }

    getOrders = (pageNo, pageSize) => {
        this.setState(
            {
                paginationLoading: true
            }
        );

        let paginationUrlString = "";

        if(pageNo || pageNo === 0){
            paginationUrlString = "?pageNo=" + pageNo + "&pageSize=" + pageSize;
        }

        let jwt = getJwt();

        axios.get(this.state.urls.environment.development +
            this.state.urls.apiVersion +
            this.state.urls.order.paginate +
            paginationUrlString, {
            'headers': {
                'Authorization': jwt
            }
        }).then((response) => {
            this.setState(
                {
                    paging: response.data,
                    orders: response.data.content
                }
            );

            if(response.status === 200) {
                this.setState(
                    {
                        loading: false,
                        paginationLoading: false
                    }
                );
            }
        }).catch(
            (err) => {
                console.log(err);
                this.setState(
                    {
                        paginationLoading: false
                    }
                );
            }
        );
    }

    updateOrderStatus = async (id, status, orderNr = false) => {
        let order = [
            {
                status: status,
                orderNr: orderNr
            }
        ]

        await axios.patch(
            this.state.urls.environment.development +
            this.state.urls.apiVersion +
            this.state.urls.order.patch, order).then(
            (res) => {
                this.getOrders();
                if(orderNr){
                    setTimeout(
                        function(){
                            this.getOrderInfo(orderNr);
                        }.bind(this), 200
                    );
                }
                infoMessageToast("Užsakymo statusas atnaujintas.");
            }
        ).catch(
            (error) => {
               console.log(error)
            }
        );
    }

    deleteOrder = (id) => {
        axios.delete(this.state.urls.environment.development +
            this.state.urls.apiVersion +
            this.state.urls.order.delete +
            id).then(
            (result) => {
                this.getOrders();
                this.cleanUpAfterDeletion();
                infoMessageToast("Užsakymas ištrintas.");
            }
        ).catch(
            (error) => {
                console.log(error)
            }
        );
    }

    setToDelete = (id) => {
        this.setState({
            orderIdToDelete: id
        })
    }

    cleanUpAfterDeletion = () => {
        this.setState({
            orderIdToDelete: null
        })
    }


    componentDidMount() {
        authenticate();
        this.getOrders();
        this.forceUpdate();
        this.checkAdminAccess();
    }

    render() {
        if (this.state.admin !== true) {
            return <Redirect to={{
                pathname: '/'
            }} />
        }
        let products = {};
        if (this.state.order.products){
            this.state.order.products.forEach(function (arrayItem) {
               products[arrayItem.id] = arrayItem.name
            });
        }
        let pagesumber = this.state.paging.pageable.offset;

        const orders = this.state.orders.map((order, key) =>
            <tbody>
            <td>{pagesumber += 1}</td>
            <td>{order.userId.name} {order.userId.lastName}</td>
            <td>{order.orderNr}</td>
            <td >{order.createdAt}</td>
            <td>
                    <span className={"badge " + this.mapOrderStatus(order.status).class}>
                        { this.mapOrderStatus(order.status).name }
                    </span>
            </td>
            <td>
                <div className="dropdown d-inline-block">
                    <button className="btn badge plc-badge-action m-0"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                        <i className="fa fa-ellipsis-h fa-lg"
                                          aria-hidden="true"></i>
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a className="dropdown-item"
                           href="#"
                           onClick={
                            () => this.updateOrderStatus(
                                order.id,
                                1,
                                order.orderNr)}>Laukiama
                        </a>
                        <a className="dropdown-item"
                           href="#"
                           onClick={
                            () => this.updateOrderStatus(
                                order.id,
                                2,
                                order.orderNr)}>Priimta
                        </a>
                        <a className="dropdown-item"
                           href="#"
                           onClick={
                            () => this.updateOrderStatus(
                                order.id,
                                3,
                                order.orderNr)}>Išsiųsta
                        </a>
                        <a className="dropdown-item"
                           href="#"
                           onClick={
                            () => this.updateOrderStatus(
                                order.id,
                                4,
                                order.orderNr)}>Pristayta
                        </a>
                        <a className="dropdown-item"
                           href="#"
                           onClick={
                            () => this.updateOrderStatus(
                                order.id,
                                5,
                                order.orderNr)}>Atšaukta
                        </a>
                    </div>
                </div>
                <button className="btn badge plc-badge-action d-inline-block"
                        type="button"
                        onClick={() => this.getOrderInfo(order.orderNr)}
                        data-toggle="modal"
                        data-target="#orderModal">
                    <i className="fa fa-search fa-lg"
                       aria-hidden="true"></i>
                </button>
                <button onClick={() => this.setToDelete(order.orderNr)}
                        className="btn badge plc-badge-action d-inline-block"
                        type="button"
                        data-toggle="modal"
                        data-target="#confirmDelete">
                    <i className="fa fa-trash-restore-alt fa-lg"
                       aria-hidden="true"></i>
                </button>
            </td>
            </tbody>
        );

        return (
            <div className={"plc-admin-bg content"}>
                <div className="row p-4">
                    <div className="col-12">
                        <h3 className="font-weight-light mb-2">Placental užsakymų valdymas</h3>
                    </div>
                </div>
                <div className="row p-4">
                    <div className="col-12">
                        <h5 className="font-weight-light mb-4">Užsakymai</h5>
                        <table className="table table-borderless">
                            <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Vardas</th>
                                <th scope="col">Užsakymo nr.</th>
                                <th scope="col">Data</th>
                                <th scope="col">Statusas</th>
                                <th scope="col">Veiksmas</th>
                            </tr>
                            </thead>
                            <tbody className={this.state.loading === true ? '' : 'd-none'}>
                                <td  className={'text-center pt-5'}
                                     colSpan={'7'}>
                                <fa className={'text-center fa fa-lg fa-sync fa-spin'}></fa>
                                    &nbsp; <span>Loading... </span>
                                </td>
                            </tbody>
                            { orders }
                        </table>
                        {this.pagination()}
                    </div>
                </div>

                <div className={"row p-4"}>
                    <div className={"col-12"}>
                        <h5 className="font-weight-light mb-4">Statistika</h5>
                    </div>
                </div>

                <div className="modal fade"
                     id="orderModal"
                     aria-labelledby="orderModal"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content plc-admin-bg">
                            <div className="modal-header border-0">
                                <h5 className="modal-title font-weight-light"
                                    id="orderModal">
                                    {this.state.user.name} {this.state.user.lastName}
                                </h5>
                            </div>
                            <div className="modal-body">
                                <div className={"row mb-5"}>
                                    <div className="col-6">
                                        <span className={"d-inline-block badge " + this.mapOrderStatus(this.state.order.status).class}>
                                            { this.mapOrderStatus(this.state.order.status).name }
                                        </span>
                                    </div>
                                    <div className="col-6 text-right">
                                        <div className="dropdown">
                                            <button className="btn badge plc-badge-btn"
                                                    type="button"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false">
                                                Keisti statusą
                                            </button>
                                            <div className="dropdown-menu"
                                                 aria-labelledby="dropdownMenuButton">
                                                <a className="dropdown-item"
                                                   href="#"
                                                   onClick={() => this.updateOrderStatus(
                                                       this.state.order.id,
                                                       1,
                                                       this.state.order.orderNumber)}>Laukiama
                                                </a>
                                                <a className="dropdown-item"
                                                   href="#"
                                                   onClick={() => this.updateOrderStatus(
                                                       this.state.order.id,
                                                       2,
                                                       this.state.order.orderNumber)}>Priimta
                                                </a>
                                                <a className="dropdown-item"
                                                   href="#"
                                                   onClick={
                                                    () => this.updateOrderStatus(
                                                        this.state.order.id,
                                                        3,
                                                        this.state.order.orderNumber)}>Išsiųsta
                                                </a>
                                                <a className="dropdown-item"
                                                   href="#"
                                                   onClick={
                                                    () => this.updateOrderStatus(
                                                        this.state.order.id,
                                                        4,
                                                        this.state.order.orderNumber)}>Pristayta
                                                </a>
                                                <a className="dropdown-item"
                                                   href="#"
                                                   onClick={
                                                    () => this.updateOrderStatus(
                                                        this.state.order.id,
                                                        5,
                                                        this.state.order.orderNumber)}>Atšaukta
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h5 className="modal-title font-weight-light">Užsakymo informacija</h5>
                                <table className="table table-borderless admin-order-table mt-3 mb-0">
                                    <tr>
                                        <td>Užsakymo numeris</td>
                                        <td>{this.state.order.orderNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Vardas ir pavardė</td>
                                        <td>
                                            {this.state.order.user && (
                                                this.state.order.user.name +
                                                " " + this.state.order.user.lastName
                                            )}
                                            {this.state.order.user &&
                                                this.state.order.user.name === "" &&
                                                this.state.order.user.lastName === "" && (
                                                    "Duomenų nėra"
                                                )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Telefono numeris</td>
                                        <td>
                                            {this.state.order.user && (
                                                this.state.order.user.phone
                                            )}
                                        </td>
                                    </tr>
                                        <tr>
                                            <td>Adresas</td>
                                            <td>
                                                {this.state.order.user && (
                                                    this.state.order.user.country !== null ?
                                                        this.state.order.user.country + " " : ""
                                                )}

                                                {this.state.order.user && (
                                                    this.state.order.user.city !== null ?
                                                        this.state.order.user.city + " " : ""
                                                )}

                                                {this.state.order.user && (
                                                    this.state.order.user.street !== null ?
                                                        this.state.order.user.street + " " : ""
                                                )}

                                                {this.state.order.user && (
                                                    this.state.order.user.postalCode !== null ?
                                                        this.state.order.user.postalCode + " " : ""
                                                )}
                                            </td>
                                        </tr>

                                </table>

                                <h6 className="font-weight-normal mt-5">Užsakymas</h6>
                                <table className="table table-borderless ">
                                    <tr>
                                        <th>Nr</th>
                                        <th>Pavadinimas</th>
                                        <th>Kaina</th>
                                        <th>Kiekis</th>
                                        <th>Suma</th>
                                    </tr>
                                    {this.state.order.orders && (
                                        this.state.order.orders.map(function(name, index){
                                                return <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{products[name.productId]}</td>
                                                            <td>{name.price.toFixed(2)} €</td>
                                                            <td>{name.quantity} vnt.</td>
                                                            <td>{(name.quantity * name.price).toFixed(2)} €</td>
                                                        </tr>;
                                            })
                                    )}
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td>{this.state.order.totalAmount && (
                                            this.state.order.totalAmount
                                        )} €</td>
                                    </tr>
                                </table>

                            </div>
                            <div className="modal-footer border-0">
                                <button type="button"
                                        className="btn badge plc-badge-btn"
                                        data-dismiss="modal">Uždaryti
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade"
                     id="confirmDelete"
                     tabIndex="-1"
                     aria-labelledby="confirmDelete"
                     aria-hidden="true">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content plc-admin-bg">
                            <div className="modal-body">
                                <div className={"row"}>
                                    <div className="col-12">
                                        <h5>
                                            <i className="fa fa-lg fa-exclamation-circle"
                                               aria-hidden="true"></i>&nbsp;
                                            <span className={'font-weight-normal'}>
                                                Ar tikrai norite ištrinti užsakymą?
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer border-0">
                                <button type="button"
                                        className="btn badge plc-badge-pending"
                                        onClick={() => this.deleteOrder(this.state.orderIdToDelete)}
                                        data-dismiss="modal">
                                    Ištrinti
                                </button>
                                <button type="button"
                                        className="btn badge plc-badge-action"
                                        data-dismiss="modal">
                                    Atšaukti
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default AdminOrders