import Swal from "sweetalert2";
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min'
import axios from "axios";
import {getUrls} from "./Config";

export const  getJwt = () => {
    return  localStorage.getItem('jwt-access');
};

export const getCart = () => {
    return  localStorage.getItem('cartItems');
};

export const getDNS = () => {
    return window.location.href;
};

export const authenticate = () => {
    const jwt = getJwt();
    const url = getUrls();
    if(jwt){
        axios.get(url.environment.development + url.apiVersion + url.user.validateJWT + "?jwt=" + jwt, {
            'headers': {
                'Authorization': jwt
            }
        }).then((response) => {}).catch(
            (err) => {
                localStorage.clear();
            }
        );
        return true;
    } else {
        return false;
    }
};

export const getToken = (url) => {
    let tokenObj = new URLSearchParams(url);
    let token = tokenObj.get('token');
    return token ? token : false;
}

export const errorMessage = (error) => {
    console.log(error);
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    swalWithBootstrapButtons.fire(
        {
            title: 'Klaida!',
            text: 'Greičiausiai tai ne Jūs, o mes! Mėginkite dar kartą!',
            icon: 'error',
            confirmButton: 'btn btn-warning',
            confirmButtonText: 'Uždaryti',
        }
    );
};


export const infoMessageToast = (message, icon_code = "fa-check") => {
    iziToast.show({
        id: null,
        class: '',
        title: '',
        titleColor: '',
        titleSize: '',
        titleLineHeight: '',
        message: message,
        messageColor: '#00c9a7',
        messageSize: '12',
        messageLineHeight: '',
        backgroundColor: '',
        theme: '', // dark, light
        color: '#E9FAF6', // blue, red, green, yellow
        icon: 'fa ' + icon_code,
        iconText: '',
        iconColor: '#00c9a7',
        iconUrl: null,
        image: '',
        imageWidth: 50,
        maxWidth: null,
        zindex: null,
        layout: 1,
        balloon: false,
        close: true,
        closeOnEscape: false,
        closeOnClick: false,
        displayMode: 0, // once, replace
        position: 'topLeft', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
        target: '',
        targetFirst: true,
        timeout: 3000,
        rtl: false,
        animateInside: true,
        drag: true,
        pauseOnHover: true,
        resetOnHover: false,
        progressBar: false,
        progressBarColor: '#00c9a7',
        progressBarEasing: 'linear',
        overlay: false,
        overlayClose: false,
        overlayColor: 'rgba(0, 0, 0, 0.6)',
        transitionIn: 'fadeInUp',
        transitionOut: 'fadeOut',
        transitionInMobile: 'fadeInUp',
        transitionOutMobile: 'fadeOutDown',
        buttons: {},
        inputs: {},
        onOpening: function () {},
        onOpened: function () {},
        onClosing: function () {},
        onClosed: function () {}
    });

};