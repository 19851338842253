import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import BreadCrumbIcon from "../helpers/BreadCrumbIcon";

class Wholesale extends React.Component{
    render() {
        return (
            <div className={"content"}>
                <div className={'row p-4'}>
                    <div className={'col-12 breadcrumbs mb-4'}>
                        <small>
                            <BreadCrumbIcon />
                            <NavLink className={'text-muted'}
                                     to={'/'}>Titulinis</NavLink>
                            <i className="fa fa-angle-right text-muted"></i>
                            <NavLink className={'text-muted ml-2'}
                                     to={'/wholesale'}>Puikus efektas, geresnė kaina</NavLink>
                        </small>
                    </div>
                </div>

                <div className="row p-4">
                    <div className={"col-12"}>
                        <h3 className="font-weight-light mb-2">Specialios sąlygos įsigyti Placental kremą</h3>
                                <h4 className={'font-weight-light mt-5 mb-3'}>Lojalus klientas</h4>
                                <h6 className="font-weight-light"
                                    style={{"line-height":"1.6"}}>Klientas mums yra labai
                                    svarbus. Visada dėl Jūsų stengiamės ir norime tobulėti kartu. Siekdami palaikyti
                                    gerą santykį su Jumis, stengiamės gaminti ne tik geriausią kremą, bet ir suteikti
                                    puikias apsipirkimo sąlygas, kurios paskatintų naudoti Placental kremą reguliariai.
                                    Savo elektroninėje parduotuvėje mes taikome įvairias nuolaidas lojaliems klientams.
                                    Jums tereikia užsiregistruoti mūsų sistemoje ir rinkti lojalumo taškus, kurie Jūsų
                                    profilyje atsispindės kaip papildomos „žvaigždutės“. Jas galėsite matyti prisijungę
                                    prie savo paskyros. Nuolaidos bus taikomos automatiniu būdu perkant prisijungus prie
                                    sistemos arba nurodytu elektroniniu paštu bus siunčiami laiškai su Jums skirtais
                                    nuolaidų kodais.</h6>

                                <h4 className={'font-weight-light mt-5 mb-3'}>Gydymo programos</h4>
                                <h6 className={'font-weight-light'}
                                    style={{"line-height":"1.6"}}>Glaudžiai bendraujame
                                    ir bendradarbiaujame su klinikomis, kurios rekomenduoja Placental ne tik kaip grožio
                                    produktą, bet ir kaip veido odos problemų gydymo priemonę. Jei Placental kremas Jums
                                    buvo paskirtas kaip gydomoji priemonė, mes stengsimės prisidėti prie maksimalaus
                                    efekto ir pasiūlyti Jums palankias kremo įsigyjimo programas. Kadangi kiekviena
                                    situacija yra individuali – daugiau informacijos pateiksime, kai kreipsitės į mus
                                    asmeniškai. Viskas, ką reikia padaryti, tai parašyti mums laišką info@placental.lt
                                    ir pateikti visą turimą informaciją. Mūsų kolegos susisieks su Jumis dėl tolimesnių
                                    veiksmų ir rekomendacijų.</h6>


                                <h4 className={'font-weight-light mt-5 mb-3'}>Didmeninė prekyba</h4>
                                <h6 className={'font-weight-light'}
                                    style={{"line-height":"1.6"}}>Jei esate gydytojas,
                                    vadovaujate gydymo įstaigai ar grožio klinikai ir norėtumėte naudoti Placental kremą
                                    kasdieninėje savo veikloje susisiekite su mumis – ir mes supažindinsime Jus su
                                    Placental didmeninės prekybos sąlygomis. Mūsų kontaktus rasite skiltyje
                                    <NavLink to={'/contacts'}><h6 className={'font-weight-light d-inline-block'}>
                                        „Kontaktai“</h6></NavLink></h6>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Wholesale)