import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import BreadCrumbIcon from "../helpers/BreadCrumbIcon";

class Payments extends React.Component{
    render() {
        return (
            <div>
                <div className={'row p-4'}>
                    <div className={"col-2"}></div>
                    <div className={'col-8 breadcrumbs mb-4'}>
                        <small>
                            <BreadCrumbIcon />
                            <NavLink className={'text-muted'}
                                     to={'/'}>Titulinis</NavLink>
                            <i className="fa fa-angle-right text-muted"></i>
                            <NavLink className={'text-muted ml-2'}
                                     to={'/payments'}>Mokėjimai</NavLink>
                        </small>
                    </div>
                    <div className={"col-2"}></div>
                </div>
                <div className={'row p-4'}>
                    <div className={'col-sm-2'}>&nbsp;</div>
                    <div className={'col-sm-8'}>
                        <h3 className="font-weight-light mb-2">Mokėjimai</h3>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Integruoti mokėjimai</h4>
                        <h6 className="font-weight-light"
                            style={{"line-height":"1.6"}}>Jūsų
                            patogumui svetainėje įdiegėme saugaus atsiskaitymo mechanizmą. Jis yra saugus, patogus ir
                            rekomenduojamas Mūsų kompanijos.</h6>
                        <h4 className={'font-weight-light mt-5 mb-3'}>Elektroninė bankininkystė</h4>
                        <h6 className="font-weight-light"
                            style={{"line-height":"1.6"}}>Jei esate
                            įpratę naudotis elektronine bankininkyste, galite drąsiai tai daryti ir toliau. Pavedimo
                            paskirtyje nurodykite užsakymo numerį arba kremo pavadinimą ir tai yra viskas, ko mums
                            reikia! Pavedimą atlikite naudodami šiuos rekvizitus:
                            <ul style={{"list-style": "none"}}
                                className={"pl-0 mt-3"}>
                                <h5 className={'font-weight-light'}>Placental</h5>
                                <li>Įmonės kodas 305163972</li>
                                <li>Adresas: Algirdo g. 46-309, LT-03209 Vilnius</li>
                                <li>AB SEB Bankas</li>
                                <li>Atsiskaitomoji sąskaita: LT74 7044 0600 0829 1102</li>
                                <li>Mokėjimo paskirtyje galite nurodyti sąskaitos numerį, užsakymo numerį arba produkto
                                    pavadinimą, pavyzdžiui "už Placental kremą"</li>
                            </ul>
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Grynieji pinigai</h4>
                        <h6 className="font-weight-light"
                            style={{"line-height":"1.6"}}>Jei
                            neturite galimybės atsiskaityti aukščiau nurodytais būdais, siūlome atsiskaitymą grynaisiais
                            pinigais. <span className={"text-danger"}>Svarbu!</span> Grynaisiais pinigais atsiskaitymai
                            priimami tik Vilniaus mieste.</h6>
                    </div>
                    <div className={'col-sm-2'}>&nbsp;</div>
                </div>
            </div>
        )
    }
}

export default withRouter(Payments)