import React, {useEffect, useState} from "react";
import {Redirect, withRouter} from "react-router-dom";
import {getCart, getJwt} from "./Helper";
import ProceedPayment from './ProceedPayment';
import OrderSummary from './OrderSummary';
import {getUrls} from "./Config";
import {productData} from "./data/ProductData";

import axios from "axios";
import CheckoutProcess from "./checkout/CheckoutProcess";

function Payment(props) {
    const [orderNr, setOrderNr] = useState(null);
    const [userId, setUserId] = useState(null);
    const [productId, setProductId] = useState(null);
    const [urls, setUrls] = useState(getUrls());
    const [paymentMethod, setPaymentMethod] = useState("INTEGRATED");
    const [paymentMethodLabel, setPaymentMethodLabel] = useState("Atsiskaitymas per svetainę");
    const [products, serProducts] = useState({});
    const [proceedPayment, setProceedPayment] = useState(false);
    const [orderSummary, setOrderSummary] = useState(true);
    const [progress, setProgress] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const getAuthLink = async (email) => {
        const response = await axios.post(
            urls.environment.development + urls.apiVersion + urls.payments.authorizationLink,
            email,
            {
                headers: {
                    'Content-Type': "text/plain"
                }
            }
        )
        return response.data.authorizationLink;
    }

    const getUserEmail = async () => {
        const username = localStorage.getItem("username")
        if (username !== null){
            const jwt = getJwt();
            return (
                (await axios.get(
                    urls.environment.development + urls.apiVersion + urls.user.profile, {
                        'headers': {'Authorization': jwt}
                    })).data.email
            );
        }
        return JSON.parse(localStorage.getItem('anonymous-user'))["email"];
    }

    const orderInitiated = (orderNr) => {
        localStorage.setItem("orderInitiated", 'true');
        localStorage.setItem("orderNr", orderNr);
    }

    const getProductId = () => {
        axios.get(
            urls.environment.development + urls.apiVersion + urls.order.product
        ).then((response) => {
                setProductId(response.data)
        }).catch(
            (err) => {
                console.log(err);
            }
        );
    };

    const generateRandomString = () => {
        return Math.random().toString(36).substring(7);
    }

     const getProducts = async () => {
        return await axios.get(
            urls.environment.development + urls.apiVersion + urls.order.product
        ).then((response) => {
            return response.data;
        }).catch(
            (err) => {
                console.log(err);
            }
        );
    }

    const storeAnonymousUser = async () => {
        let user = JSON.parse(
            localStorage.getItem('anonymous-user')
        );

        user.password = generateRandomString();
        user.username = user.name.toLowerCase() + "_anonymous_" + generateRandomNumber();
        user.anonymous = true;
        user.lastName = user.last_name;
        user.postalCode = user.postal_code;
        user.street = user.address;

        const anonymousUser = await axios.post(
                urls.environment.development + urls.apiVersion + urls.user.register, user
        )

        return anonymousUser.data.id;
    }

    const getUserId = async () => {
        let jwt = getJwt();
        const user = await axios.get(
            urls.environment.development + urls.apiVersion +urls.user.profile, {
            'headers': {
                'Authorization': jwt
            }
        })
        return user.data.id
    }

    const getProductPrice = (product) => {
        return productData[0][""+ product +""].price
    }

    const buildOrder = async (userID, orderNumber) => {
        let products = await getProducts();
        let items = JSON.parse(
            localStorage.getItem("cartItems")
        );

        delete items.count;

        let productsIds = {};
        let order = [];

        for (const [key] of Object.entries(products)) {
            productsIds[""+products[key].description+""] = products[key].id;
        }

        for (const [key, value] of Object.entries(items)) {
            let orderDetails = {
                userId: userID,
                productId: null,
                uuid: generateRandomNumber(),
                status: 1,
                quantity: null,
                price: null,
                paid: 0,
                paymentMethod: paymentMethod,
                orderNr: orderNumber,
            }

            if(value > 0){
                orderDetails.productId = productsIds[""+key+""];
                orderDetails.quantity = value;

                if (localStorage.getItem('discount') !== null){
                    orderDetails.price = getProductPrice(key) - getProductPrice(key) * .25;
                }else{
                    orderDetails.price = getProductPrice(key);
                }

                order.push(orderDetails);
            }
        }

        return order;
    }

    const placeOrder = async (userID, orderNumber) => {
        orderInitiated(orderNumber);
        const order = await buildOrder(userID, orderNumber);
        console.log(order);
        return axios.post(urls.environment.development + urls.apiVersion + urls.order.post, order)
            .catch(
                (error) => {
                console.log(error);
                localStorage.removeItem('orderInitiated');
                localStorage.removeItem('orderNr');
                setError(true);
                setSuccess(false);
                setProgress(false);
                setOrderSummary(false);
            }
        );
    }

    const getPaymentMethod = () => {
        const paymentMethod = localStorage.getItem("paymentMethod");
        setPaymentMethod(paymentMethod);
        mapPaymentMethodNaming(
            localStorage.getItem("paymentMethod")
        );
        return paymentMethod;
    }

    const mapPaymentMethodNaming = (paymentMethod) => {
        switch(paymentMethod) {
            case "INTEGRATED":
                setPaymentMethodLabel("Atsiskaitymas per svetainę");
                break;
            case "BANKING":
                setPaymentMethodLabel("El. bankininkystė");
                break;
            case "CASH":
                setPaymentMethodLabel("Grynieji pinigai");
                break;
            default:
                setPaymentMethodLabel("Atsiskaitymas per svetainę");
        }
    }

    const generateRandomNumber = () => {
        return Math.floor(100000 + Math.random() * 900000);
    }

    const generateOrderNumber = async () => {
        return (await axios.get(urls.environment.development + urls.apiVersion + urls.order.generateOrderNumber)).data;
    }

    const cleanPaymentDataInBrowserStorage = () => {
        localStorage.removeItem('orderInitiated');
        localStorage.removeItem('anonymous-user');
        localStorage.removeItem('orderNr');

        localStorage.setItem(
            "cartItems",
            JSON.stringify(
                {
                    "cream":0,
                    "serum":0,
                    "set":0,
                    "exosomes":0,
                    "setExosomesCreamSerum":0,
                    "setExosomesCream":0,
                    "setExosomesSerum": 0,
                    "count":0
                    }
                )
        );
    }

    const processOrder = async () => {
        getProductId();

        const paymentMethod = getPaymentMethod();

        let orderNumber = await generateOrderNumber();

        setProgress(true);
        setOrderSummary(false);

        let anonymousUserObject = JSON.parse(
            localStorage.getItem("anonymous-user")
        );
        let jwt = getJwt();
        let userID = null;

        if (anonymousUserObject != null && jwt === null) {

            const anonymousUserCreatedID = await storeAnonymousUser();

            console.log(anonymousUserCreatedID);

            anonymousUserObject['id'] = anonymousUserCreatedID;

            localStorage.setItem(
                'anonymous-user',
                JSON.stringify(anonymousUserObject)
            );

            userID = anonymousUserCreatedID;
        } else {
            userID = await getUserId();
        }

        setUserId(userID);

        await placeOrder(userID, orderNumber);

        if(paymentMethod === "INTEGRATED"){
            const email = await getUserEmail();
            const link = await getAuthLink(email)
            window.location.replace(link);
        }else{
            setError(false);
            setSuccess(true);
            setProgress(false);
            setOrderSummary(false);
            cleanPaymentDataInBrowserStorage();
        }
    }

    useEffect(() => {
        if (props.price * JSON.parse(
                getCart()).count <= 0 &&
            localStorage.getItem('paymentInitiated') === null ||
            localStorage.getItem('userDataValid') === 'false') {
            return <Redirect to={{
                pathname: '/cart'
            }} />
        }

        buildOrder().then(() => {});
        getPaymentMethod();
        props.clearCart();
    }, []);

    return (
        <div className={"content"}>

            {proceedPayment && (
                <ProceedPayment
                    clearCart = {() => props.clearCart()}
                    orderNr = {orderNr}
                />
            )}

            {orderSummary && (
                <OrderSummary
                    paymentMethod = {paymentMethodLabel}
                    processOrder = {
                        () => processOrder()
                    }
                />
            )}

            <CheckoutProcess
                progress = {progress}
                success = {success}
                error = {error}
                clearCart = {
                    () => props.clearCart()
                }
            />

            {orderSummary && (
                <div className={'row'}>
                    <div className={'col-12 p-4'}>
                        <a href="#"
                           onClick={
                                () => processOrder()
                           }
                           className={'btn btn-primary mt-5 ml-3'}>
                            Tvirtinti
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
}

export default withRouter(Payment)