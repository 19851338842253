import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import BreadCrumbIcon from "../helpers/BreadCrumbIcon";

class FAQ extends React.Component{
    render() {
        return (
            <div className={"content"}>
                <div className={'row p-4'}>
                    <div className={'col-12 breadcrumbs mb-4'}>
                        <small>
                            <BreadCrumbIcon />
                            <NavLink className={'text-muted'}
                                     to={'/'}>Titulinis</NavLink>
                            <i className="fa fa-angle-right text-muted"></i>
                            <NavLink className={'text-muted ml-2'}
                                     to={'/faq'}>DUK</NavLink>
                        </small>
                    </div>
                </div>

                <div className={'row p-4'}>
                    <div className={'col-sm-12'}>
                        <h3 className="font-weight-light mb-2">Dažnai užduodami klausimai (DUK)</h3>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Kas yra kamieninės ląstelės?</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Kamieninės ląstelės pasižymi unikaliomis savybėmis. Pirma, jos gali „savarankiškai
                            atsinaujinti“, joms dalijantis susidaro daugiau tos pačios rūšies ląstelių. Antra savybė –
                            jos gali subręsti arba diferencijuoti į specializuotas ląsteles, kurios atlieka specifinę
                            funkciją organizme (pvz.: kraujo, raumenų, odos ląsteles). Yra daug skirtingų kamieninių
                            ląstelių tipų. Tai įvairių rūšių suaugusio žmogaus kamieninės ląstelės, kurios randamos
                            mūsų kūno audiniuose, bei embrioninės kamieninės ląstelės, kurios egzistuoja tik
                            ankstyvosiose vystymosi stadijose. Neseniai iš specializuotų suaugusio organizmo ląstelių
                            buvo sukurtos ląstelės, pasižyminčios panašiomis savybėmis į embrionines kamienines
                            ląsteles. Jos vadinamos indukuotomis pluripotentinėmis kamieninėmis ląstelėmis (angl. iPS
                            cells – induced pluripotent stem cells).
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Kokią įtaką kamieninės ląstelės daro mūsų odai?</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Mūsų odą sudaro tūkstančiai įvairių ląstelių, kurios nuolat atsinaujina. Šis procesas nevyktų be
                            kamieninių ląstelių. Būtent dėl jų regeneruojasi laiko ar traumos pažeisti odos plotai.
                            Kamieninės ląstelės teigiamai veikia odos būklę, gerina kraujotaką aprūpindamos odą deguonimi ir
                            maisto medžiagomis, slopina uždegiminius procesus. Deja,  senstant, veikiant žalingiems aplinkos
                            veiksniams (oro užterštumui, UV spinduliams), vykstant
                            medžiagų apykaitos procesams, organizme susidaro laisvųjų radikalų, kurie kenkia kamieninėms ląstelėms,
                            tiksliau, jas naikina. Mažėjant natūraliai odoje esančių kamieninių ląstelių kiekiui, lėtėja ir
                            jos atsinaujinimas. Oda ima plonėti, sausėti, atsiranda pirmosios raukšlės, kurios ilgainiui ryškėja.
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>
                            Kokiam veido odos tipui labiausiai tinka kremas Placental?
                        </h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Veido kremas Placental tinka visiems veido odos tipams, nes yra unikalios sudėties bei
                            pasižymi išskirtinėmis regeneracinėmis savybėmis. Kremo sudėtyje yra gyvybiškai svarbių
                            veikliųjų medžiagų, kurios padeda išlaikyti reikiamą drėgmės kiekį, stangrina bei skaistina
                            veido odą.
                        </h6>


                        <h4 className={'font-weight-light mt-5 mb-3'}>Kuo ypatingas veido kremas Placental?</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Kremą sudaro drėkinamojo kremo bazė, praturtinta placentos ekstraktu. Placenta turtinga
                            proteinų (baltymų), enzimų (fermentų), lipidų, gliucidų bei mineralinių medžiagų, būtinų
                            gyvybės palaikymui. Tai neįkainojama natūrali gamtos dovana, gamtinių biostimuliatorių
                            rinkinys. Įrodyta, kad placentos proteinai (baltymai) gerina deguonies pasisavinimą epitelio
                            ląstelėse, stimuliuoja ląstelių metabolinius procesus, dėl to oda aktyviai drėkinama,
                            stangrinama, atkuriamas odos imunitetas. Atnaujindamas bei regeneruodamas ląsteles, kremas
                            veikia prieš pigmentaciją, mažėja amžiaus dėmės. Biologiškai aktyvias medžiagas oda lengvai
                            atpažįsta, puikiai pasisavina, dėl to oda tampa lygi ir skaisti, atkuriamos odos gyvybinės
                            funkcijos.
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>
                            Ar veido kremas Placental tinka jautriai veido odai?
                        </h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Jautri veido oda įprastai reaguoja į įvairius kosmetikos produktuose esančius alergenus:
                            kvapiąsias medžiagas, dažiklius, eterinių aliejų komponentus, vitaminus, gyvulinius baltymus
                            ir kt. Placental produktas su placenta –biochemijos mokslininkų sukurtas
                            kremas su placentos ekstraktu, skirtas visiems odos tipams. Sudėtyje nėra kosmetikos
                            pramonėje įprastų alergenų, todėl jis neturėtų dirginti net ir jautrios veido odos.
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Kaip naudoti veido kremą Placental?</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Kremą naudokite kas antrą parą tepdami jį ant švariai nuvalytos veido odos. Siekiant
                            geriausio rezultato, Placental kremą rekomenduojama tepti nakčiai – miego metu aktyviosios
                            medžiagos geriausiai veikia odą.
                        </h6>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(FAQ)