import React from "react";
import Error from "../helpers/Error"

class CheckoutError extends React.Component{
    render() {
        return (
            <Error />
        )
    }
}

export default CheckoutError