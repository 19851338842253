import React, {useState} from "react";
import {Col, Row, Spin} from 'antd';

function CheckoutProgress(){
    return (
        <>
            <Row style={{
                maxWidth:"1024px",
                margin: "0 auto",
                textAlign: "center"
            }}>
                <Col
                    span={24}
                    style={{padding: 48}}
                >
                    <Spin tip="Ruošiame Jūsų užsakymą, prašoma palaukti..." size="large">
                        <div className="content" />
                    </Spin>
                </Col>
            </Row>
        </>
    )
}

export default CheckoutProgress