import {Button, Col, Result, Row, Spin} from 'antd';
import React, {useEffect, useState} from "react";
import axios from "axios";
import CheckoutSuccess from "../CheckoutSuccess";
import {NavLink} from "react-router-dom";
import {getUrls} from "../../Config";

function PaymentConfirmed(props) {
    const [urls, setUrls] = useState(getUrls());
    const [progress, setProgress] = useState(true);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);


    const getStatusGroupFromURI = () => {
        const params = new URLSearchParams(window.location.search);
        return params.get("statusGroup");
    }

    const setSuccessMessage = () => {
        setError(false);
        setSuccess(true);
        setProgress(false);
    }

    const setErrorMessage = () => {
        setError(true);
        setSuccess(false);
        setProgress(false)
    }

    const delay = async (millisec) => {
        return new Promise(resolve => {
            setTimeout(() => { resolve('') }, millisec);
        })
    }
    const checkPaymentStatusGroup = async (paymentId) => {
        const STATUS_GROUP_FROM_URI = getStatusGroupFromURI();
        if (STATUS_GROUP_FROM_URI === "completed"){
            return STATUS_GROUP_FROM_URI;
        }

        let statusGroup = await axios.get(
            urls.environment.development + urls.payments.status + paymentId
        );

        if (statusGroup.data !== "completed") {
            let i = 0
            while (i < 5) {
                i++;
                await delay(2000);
                statusGroup = await axios.get(
                    urls.environment.development + urls.payments.status + paymentId
                );

                if (statusGroup.data === "completed") {
                    break;
                }
            }
        }
        if (statusGroup.data === "completed"){
            setSuccessMessage();
        } else {
            setErrorMessage();
        }

        return statusGroup.data;
    }

    const paymentInformation = async () => {
        const PARAMS = new URLSearchParams(window.location.search);
        const PAYMENT_ID= PARAMS.get("paymentId");
        const BANK_STATUS_COMPLETED = "ACSC";

        let STATUS_GROUP= PARAMS.get("statusGroup")
        let BANK_STATUS = PARAMS.get("status");

        if (STATUS_GROUP !== "completed"){
            STATUS_GROUP = await checkPaymentStatusGroup(PAYMENT_ID);
            if (STATUS_GROUP === "completed"){
                BANK_STATUS = BANK_STATUS_COMPLETED;
            }
        }

        setSuccessMessage();

        return {
            "paymentId": PAYMENT_ID,
            "bankStatus": BANK_STATUS,
            "orderNumber": null,
            "statusGroup": STATUS_GROUP
        };
    }

    const getPaymentStatus = async () => {
        let paymentData = await paymentInformation();
        return await axios.post(
            urls.environment.development + urls.payments.update,
            paymentData
        )
    }

    const cleanPaymentDataInBrowserStorage = () => {
        localStorage.removeItem(
            'orderInitiated'
        );

        localStorage.removeItem(
            'anonymous-user'
        );

        localStorage.removeItem(
            'orderNr'
        );

        localStorage.setItem(
            "cartItems", JSON.stringify(
                {
                    "cream":0,
                    "serum":0,
                    "set":0,
                    "exosomes":0,
                    "setExosomesCreamSerum":0,
                    "setExosomesCream":0,
                    "setExosomesSerum": 0,
                    "count":0
                })
        );
    }

    useEffect(() => {
        const fetchData = async () => {
            await getPaymentStatus().then(() => {
                cleanPaymentDataInBrowserStorage();
            });
        }
        fetchData().catch( error => {
            console.log(error.message);
        });

    }, []);

    return (
        <>
            <Row style={{
                maxWidth:"1024px",
                margin: "0 auto",
                textAlign: "center"
            }}>
                <Col
                    span={24}
                    style={{padding: 48}}
                >
                    {progress &&
                        <Spin
                            tip="Vykdomas mokėjimas, prašome palaukti"
                            size="large">
                            <div className="content" />
                        </Spin>
                    }

                    {success &&
                        <CheckoutSuccess clearCart = {() => props.clearCart()}  />
                    }

                    {error &&
                        <Result
                            status="404"
                            title="Nepavyko patvirtinti mokėjimo."
                            subTitle={<>
                                        {"Bankas nepatvirtina mokėjimo baigtumo. Informuosime jus el. paštu, kai mokėjimas bus patvirtintas."}
                                        <br/>
                                        {"Dėkojame, kad perkate!"}
                                      </>}
                            extra={<NavLink to={'/'} onClick={() => props.clearCart()}>
                                        <Button type="primary">Į pradžią</Button>
                                   </NavLink>}
                        />
                    }
                </Col>
            </Row>
        </>
    );
}

export default PaymentConfirmed;