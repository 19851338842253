import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import BreadCrumbIcon from "../helpers/BreadCrumbIcon";

class BioProteinolPf extends React.Component{
    render() {
        return (
            <div className={"content"}>
                <div className={'row p-4'}>
                    <div className={'col-12 breadcrumbs mb-4'}>
                        <small>
                            <BreadCrumbIcon />
                            <NavLink className={'text-muted'} to={'/'}>Titulinis</NavLink>
                            <i className="fa fa-angle-right text-muted"></i>
                            <NavLink className={'text-muted ml-2'}
                                     to={'/bio-proteinol-pf'}>Bio-Proteinol PF</NavLink>
                        </small>
                    </div>
                </div>
                <div className={'row p-4'}>
                    <div className={'col-12'}>
                        <h3 className="font-weight-light mb-2">Bio-Proteinol PF</h3>

                        <p className={'font-weight-light text-secondary mb-3'}>Placentos ekstraktas kosmetikos reikmėms</p>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Aprašymas</h4>
                        <h6 className="font-weight-light text-justify" style={{"line-height":"1.6"}}>
                            BIO-PROTEINOL PF yra biologinis galvijų placentos ekstraktas, surinktas nuo trečio iki
                            ketvirto nėštumo mėnesio ir kontroliuojamas licencijuotų veterinarijos gydytojų. Be hormonų
                            ir estrogenų. Labai svarbu, kad placenta būtų paimta tinkamu laiku ir taip būtų išvengta
                            hormonų pernešimo problemų, ir būtų galima pasinaudoti vis dar turtingo produkto privalumais.
                            Jei placenta surenkama po gimdymo, ji bus menkavertė ir joje gali būti daug hormonų.
                            BIO-PROTEINOL PF yra natūrali fiziologiškai subalansuota terpė, idealiai tinkanti odai ir
                            plaukams gydyti.<br/><br/>
                            Jo sudėtyje gausu šių medžiagų:
                            <ul>
                                <li>Baltymai: makroglobulinai, glikoproteinai, lipoproteinai</li>
                                <li>Fermentai: šarminiai fosfatai, rūgštiniai 	fosfatai, deaminazės, pieno rūgšties
                                    dehidrogenazė</li>
                                <li>Lipidai: gliceridai, fosfolipidai</li>
                                <li>Gliucidai: heksozė, gliukozė, heksozaminai, polisacharidai</li>
                                <li>Kita: karbamidas, šlapimo rūgštis, kreatininas</li>
                            </ul>
                            Mineraliniai elementai:
                            <ul>
                                <li>Anijonai: fosfatai, chloridai ir sulfatai</li>
                                <li>Katijonai: fosfatai, chloridai ir sulfatai </li>
                                <li>Oligoelementai</li>
                            </ul>
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Kosmetinis naudojimas</h4>
                        <h6 className="font-weight-light text-justify" style={{"line-height":"1.6"}}>
                            <ul>
                                <li>Odos šviesinimas</li>
                                <li>Skatina plaukų tvirtumą</li>
                                <li>Puikus poveikis losjonuose ir šampūnuose</li>
                                <li>Raminantis natūralus skystis ir eutrofinis gydymas</li>
                                <li>Puikiai drėkina</li>
                                <li>Atkuria joninę pusiausvyrą</li>
                                <li>Rišamoji medžiaga</li>
                                <li>Natūrali homeostatinė medžiaga</li>
                            </ul>
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Savybės</h4>
                        <h6 className="font-weight-light text-justify mb-5" style={{"line-height":"1.6"}}>BIO-PROTEINOL PF
                            jau daugelį metų naudojamas specialioms odos ir plaukų procedūroms. Jis naudojamas
                            priemonėse nuo plaukų slinkimo.

                            <ul className={"mt-3"}>
                                <li>Skatina plaukų tvirtumą. Puikus poveikis losjonuose ir šampūnuose.</li>
                                <li>Skatina epitelio ląstelių deguonies suvartojimą. Skatina ląstelių medžiagų apykaitos
                                    procesą.</li>
                                <li>Puikiai drėkina audinius ir ląsteles. Atkuria joninę pusiausvyrą.</li>
                                <li>Tai – natūralus emulsiklis, biologinis riebalų ir aliejų ploviklis. Natūralus
                                    nedirginantis valiklis.</li>
                                <li>Visapusiška odos maitinimo ir drėkinimo priemonė.</li>
                                <li>Sterili biologinė priemonė. Skatina kitų veikliųjų medžiagų skvarbą.</li>
                            </ul>
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>INCI pavadinimas:</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Placentos baltymai
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Naudojimo dozė</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            5 - 10 %
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Tirpumas</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Tirpsta vandenyje ir alkoholyje (maks. 30º C)
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Konservantai</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Nėra
                        </h6>
                        <small className={"text-muted mt-5"}>Ctra. Alpedrete, 6, 28400 Collado Villalba – Madridas –
                            Ispanija, info@cobiosa.com, www.cobiosa.com, ISO 9001 – ISO 14001 – ISO 22716</small>

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(BioProteinolPf)