import React from "react";
import {getUrls} from "../Config";
import {getJwt, infoMessageToast, authenticate} from '../Helper';
import axios from "axios";

class UserOrders extends React.Component{
    constructor(props){
        super(props);
        this.state={
            id: null,
            orders: [],
            order: {},
            urls: getUrls(),
        };
        this.handleChange = this.handleChange.bind(this);
    }

    getOrderDetails = (id) => {
        let orders = this.state.orders;
        let order = {};
        let Exception = {};

        try {
            orders.forEach(function (item) {
                if (item.id === id) {
                    order = item;
                    throw Exception;
                }
            });
        } catch (e) {
            if (e !== Exception) throw e;
            this.setState({
                    order: order
                });
        }
    }


    mapOrderStatus = (status) => {
        let object = {class: "", name: ""};
        let pending = "Laukiama";
        let accepted = "Priimta";
        let dispatched = "Išsiųsta";
        let delivered = "Pristatyta";
        let canceled = "Atšaukta";
        let classPrefix = "plc-badge-";
        let classPending = classPrefix + "pending";
        let classAccepted = classPrefix + "accepted";
        let classDispatched = classPrefix + "dispatched";
        let classDelivered = classPrefix + "delivered";
        let classCanceled = classPrefix + "canceled";

        switch(status) {
            case "1":
                object.name = pending;
                object.class = classPending;
                break;
            case "2":
                object.name = accepted;
                object.class = classAccepted;
                break;
            case "3":
                object.name = dispatched;
                object.class = classDispatched;
                break;
            case "4":
                object.name = delivered;
                object.class = classDelivered;
                break;
            case "5":
                object.name = canceled;
                object.class = classCanceled;
                break;
            default:
                object.name = pending;
                object.class = classPending;
        }
        return object;
    };

    handleChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    getUserOrders = () => {
        let jwt = getJwt();

        axios.get(this.state.urls.environment.development +
            this.state.urls.apiVersion +
            this.state.urls.user.profile, {
            'headers': {
                'Authorization': jwt
            }
        }).then((response) => {
                return axios.get(this.state.urls.environment.development +
                    this.state.urls.apiVersion +
                    this.state.urls.user.orders +
                    response.data.id, {
                    'headers': {
                        'Authorization': jwt
                    }
                });
            }).then((response) => {
                this.setState({
                    orders: response.data
                });
            }).catch(
                (error) => {
                    console.log(error);
                }
                );
    }

    calculateOrderAmount = (price, quantity) => {
        return price * quantity;
    }

    componentDidMount() {
        authenticate();
        this.getUserOrders();
    }

    render() {
        const orders = this.state.orders.map((order, key) =>
            <tbody>
                <th scope="row">{key + 1}</th>
                <th>{order.productId.name}</th>
                <th>{order.orderNr}</th>
                <th>{order.createdAt}</th>
                <td>{ this.calculateOrderAmount(order.price, order.quantity)}€</td>
                <td>
                    <span className={"badge " + this.mapOrderStatus(order.status).class}>
                        { this.mapOrderStatus(order.status).name }
                    </span>
                </td>
            </tbody>
        );
        return (
            <div className={"content"}>
                <div className="row p-4">
                    <div className="col-6">
                        <h3 className="font-weight-light mb-2">Jūsų užsakymai</h3>
                    </div>

                    <div className="col-12">
                        <table className="table mt-5 plc-orders-tbl">
                            <thead>
                            <tr>
                                <th scope="col">Nr.</th>
                                <th scope="col">Prekė</th>
                                <th scope="col">Užsakymo nr.</th>
                                <th scope="col">Užsakymo data</th>
                                <th scope="col">Suma</th>
                                <th scope="col">Statusas</th>
                            </tr>
                            </thead>
                            { orders }
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

export default UserOrders