import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import BreadCrumbIcon from "../helpers/BreadCrumbIcon";

class Help extends React.Component{
    render() {
        return (
            <div className={"content"}>
                <div className={'row p-4'}>
                    <div className={'col-12 breadcrumbs mb-4'}>
                        <small>
                            <BreadCrumbIcon />
                            <NavLink className={'text-muted'}
                                     to={'/'}>Titulinis</NavLink>
                            <i className="fa fa-angle-right text-muted"></i>
                            <NavLink className={'text-muted ml-2'}
                                     to={'/help'}>Pagalba</NavLink>
                        </small>
                    </div>
                </div>
                <div className={'row p-4'}>
                    <div className={'col-12'}>
                        <h3 className="font-weight-light mb-2">Pagalba</h3>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Prekių grąžinimas</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Galima grąžinti prekę jei jos pakuotė nepažeista ir produktas nenaudotas. Taip pat priimsime
                            prekę atgal jei pateiksite įrodymus, kad prekė atvyko sugadinta. Pinigus gražinsime per 14
                            dienų arba pakeisime kitu kremu, jei pageidausite. Daugiau apie prekių grąžinimą skaitykite
                            skiltyje <NavLink to={'/return-goods'}><h6 className={'font-weight-light d-inline-block'}>
                            "Prekių grąžinimas"</h6></NavLink>
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Prekių pristatymas</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Prekes pristatome tik per kurjerius. Dirbame su patikimiausiomis Lietuvos ir užsienio pašto
                            tarnybomis.<br/><br/>
                            Su kiekvienu užsakymu gauname prekės sekimo numerį, jei pristatymas užtrunka ilgiau, galite
                            kreiptis į mus, pateiksime Jums tikslias prekės koordinates.<br/><br/>
                            Jei prekė atvyko pažeista, mes padengsime visas išlaidas ir atsiųsime Jums naują prekę
                            nemokamai. Daugiau apie prekių pristatymą skaitykite skiltyje <NavLink to={'/delivery'}>
                            <h6 className={'font-weight-light d-inline-block'}>"Prekių pristatymas"</h6></NavLink>
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>
                            Šalutinis poveikis
                        </h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Placental kremas neskatina jokių alergijų. Jei atsirado nemalonus pojūtis, veidą nuplaukite
                            gausiu kiekiu vandens ir padarykite 24 valandų pertrauką. Kitą kartą vartokite mažesnį kremo
                            kiekį, kol veido oda apsipras prie veikliųjų Placental medžiagų. Daugiau informacijos apie
                            Placental veikliąsias medžiagas skiltyje <NavLink to={'/important-to-know'}>
                            <h6 className={'font-weight-light d-inline-block'}>"Svarbu žinoti"</h6></NavLink>
                        </h6>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Help)