import React from "react";
import {NavLink, withRouter} from "react-router-dom";

class AlertWarningUserDataNotValidText extends React.Component{
    render() {
        return (
            <div>
                <small className={'text-danger d-none mt-3'}>
                    Norėdami tęsti toliau supildykite kontaktinius duomenis.
                    <NavLink className={"btn btn-sm btn-link text-danger"}
                             to={'/profile'}>
                        Vartotojo zona
                    </NavLink>
                </small>
            </div>
        )
    }
}

export default withRouter(AlertWarningUserDataNotValidText)