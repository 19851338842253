import React from "react";
import { withRouter, NavLink } from "react-router-dom";
import axios from "axios";
import {getUrls} from "./Config";
import {getJwt} from "./Helper";
import BreadCrumbIcon from "./helpers/BreadCrumbIcon";
import {productData} from "./data/ProductData";
import placentalCream from "../images/placentalCreamIcon.jpg";
import placentalSerum from "../images/placentalSerumIcon.jpg";
import placentalSet from "../images/placentalSetIcon.jpg";
import exosomesSerumIcon from "../images/exosomesSerumIcon.jpg";
import setExosomesSerum from "../images/serumExosomes.jpg";
import setExosomesCreamSerum from "../images/creamSerumExosomes.jpg";
import setExosomesCream from "../images/creamExosomes.jpg";

class OrderSummary extends React.Component{
    constructor(props){
        super(props);
        this.state={
            customer: {
                name: '',
                lastName: '',
                email: '',
                phone: '',
                address: '',
                postalCode: '',
                city: '',
                country: '',
                street: '',
            },
            urls: getUrls(),
        }
    }

    getCartItems = () => {
        return JSON.parse(
            localStorage.getItem(
                "cartItems"
            )
        );
    }

    calculateInitPrice = () => {
        let items = this.getCartItems();
        let total = 0;

        if(items){
            total = items.cream * productData[0]["cream"].price +
                items.serum * productData[0]["serum"].price +
                items.exosomes * productData[0]["exosomes"].price +
                items.set * productData[0]["set"].price +
                items.setExosomesCreamSerum * productData[0]["setExosomesCreamSerum"].price +
                items.setExosomesCream * productData[0]["setExosomesCream"].price +
                items.setExosomesSerum * productData[0]["setExosomesSerum"].price;

            if (localStorage.getItem('discount') !== null){
                let totalAfterDiscount = parseFloat(total);
                total = totalAfterDiscount - totalAfterDiscount * .25;
            }
        }
        return total;
    };

    getLoggedInCustomerDetails = () => {
        const jwt = getJwt();
        axios.get(
            this.state.urls.environment.development + this.state.urls.apiVersion + this.state.urls.user.profile,{
                'headers': { 'Authorization': jwt }
            }
        ).then((response) => {
            let customer = {};
            let anonymousUserObject = response.data;

            for (var key of Object.keys(anonymousUserObject)) {
                customer[key] = anonymousUserObject[key];
            }
            this.setState({
                customer: customer
            });
        }).catch(
            (err) => {
                console.log(err);
            }
        );
    }

    setCustomerDetailsForPayment = () => {
        let anonymousUserObject = JSON.parse(
            localStorage.getItem(
                "anonymous-user"
            )
        );

        let customer = {};

        if (anonymousUserObject != null) {
            for (let key of Object.keys(anonymousUserObject)) {
                customer[key] = anonymousUserObject[key];
            }
        }else{
            this.getLoggedInCustomerDetails();
        }
        this.setState({
            customer: customer
        });
    };

    componentDidMount(){
        this.setCustomerDetailsForPayment();
    };

    render() {
        return (
            <div>
                <div className={'row p-4'}>
                    <div className={'col-12 breadcrumbs mb-4'}>
                        <small>
                            <BreadCrumbIcon />
                            <NavLink className={'text-muted'} to={'/'}>Titulinis</NavLink>
                            <i className="fa fa-angle-right text-muted"></i>
                            <NavLink className={'text-muted ml-2'} to={'/cart'}>Krepšelis</NavLink>
                            <i className="fa fa-angle-right text-muted"></i>
                            <NavLink className={'text-muted ml-2'} to={'/checkout'}>Apmokėjimas</NavLink>
                            <i className="fa fa-angle-right text-muted"></i>
                            <span className={'ml-2'}>Suvestinė</span>
                        </small>
                    </div>
                </div>

                <div className={'row order-summary-color p-4'}>
                    <div className={'col-12'}>
                        <p className={'mt-5'}>Informacija apie produktą</p>
                        <hr/>
                        <small>
                            <table className="table table-borderless text-muted mt-3">
                                <thead className={"font-weight-bold"}>
                                <tr>
                                    <th scope="col"><p>#</p></th>
                                    <th scope="col"><p>Produkto pavadinimas</p></th>
                                    <th scope="col"
                                        className={"text-center"}><p>Vienento kaina</p></th>
                                    <th scope="col"
                                        className={"text-center"}><p>Kiekis</p></th>
                                    <th scope="col"
                                        className={"text-right"}><p>Viso</p></th>
                                </tr>
                                </thead>
                                <tbody>

                                {
                                    Object.entries(JSON.parse(localStorage.getItem(
                                        "cartItems"
                                    ))).map((key) => {
                                        let product = {};
                                        if(key[1] > 0 && key[0] !== "count"){
                                            product = {
                                                "count": key[1],
                                                "price": productData[0][key[0]].price
                                            }
                                        }else{
                                            return null;
                                        }
                                        return <tr>
                                            <td className={"align-middle"}>
                                                <img className={key[0] === 'cream' ?
                                                    'mr-3 mt-2 mb-2 float-left' :
                                                    "d-none"}
                                                     src={placentalCream}
                                                     alt={'Placental cart item'}
                                                     width={'64px'}
                                                     height={'64px'}
                                                />

                                                <img className={key[0] === 'serum' ?
                                                    'mr-3 mt-2 mb-2 float-left' :
                                                    "d-none"}
                                                     src={placentalSerum}
                                                     alt={'Placental cart item'}
                                                     width={'64px'}
                                                     height={'64px'}
                                                />

                                                <img className={key[0] === 'exosomes' ?
                                                    'mr-3 mt-2 mb-2 float-left' :
                                                    "d-none"}
                                                     src={exosomesSerumIcon}
                                                     alt={'Exosomes Serum'}
                                                     width={'64px'}
                                                     height={'64px'}
                                                />

                                                <img className={key[0] === 'set' ?
                                                    'mr-3 mt-2 mb-2 float-left' :
                                                    "d-none"}
                                                     src={placentalSet}
                                                     alt={'Placental cart item'}
                                                     width={'64px'}
                                                     height={'64px'}
                                                />

                                                <img className={key[0] === 'setExosomesCreamSerum' ?
                                                    'mr-3 mt-2 mb-2 float-left' :
                                                    "d-none"}
                                                     src={setExosomesCreamSerum}
                                                     alt={'Placental cart item'}
                                                     width={'64px'}
                                                     height={'64px'}
                                                />

                                                <img className={key[0] === 'setExosomesCream' ?
                                                    'mr-3 mt-2 mb-2 float-left' :
                                                    "d-none"}
                                                     src={setExosomesCream}
                                                     alt={'Placental cart item'}
                                                     width={'64px'}
                                                     height={'64px'}
                                                />

                                                <img className={key[0] === 'setExosomesSerum' ?
                                                    'mr-3 mt-2 mb-2 float-left' :
                                                    "d-none"}
                                                     src={setExosomesSerum}
                                                     alt={'Placental cart item'}
                                                     width={'64px'}
                                                     height={'64px'}
                                                />
                                            </td>
                                            <td className={key[0] === 'cream' ?
                                                'align-middle' :
                                                "d-none"}>
                                                <span>Face Cream</span>
                                            </td>

                                            <td className={key[0] === 'serum' ?
                                                'align-middle' :
                                                "d-none"}>
                                                <span>Face Serum</span>
                                            </td>
                                            <td className={key[0] === 'exosomes' ?
                                                'align-middle' :
                                                "d-none"}>
                                                <span>Exosomes Serum</span>
                                            </td>
                                            <td className={key[0] === 'set' ?
                                                'align-middle' :
                                                "d-none"}>
                                                <span>Face Cream and Serum</span>
                                            </td>

                                            <td className={key[0] === 'setExosomesCreamSerum' ?
                                                'align-middle' :
                                                "d-none"}>
                                                <span>Exosomes, Cream and Serum</span>
                                            </td>

                                            <td className={key[0] === 'setExosomesCream' ?
                                                'align-middle' :
                                                "d-none"}>
                                                <span>Exosomes and Cream</span>
                                            </td>

                                            <td className={key[0] === 'setExosomesSerum' ?
                                                'align-middle' :
                                                "d-none"}>
                                                <span>Exosomes and Serum</span>
                                            </td>
                                            <td className={"align-middle text-center"}>
                                                <span>{product.price}€</span>
                                            </td>
                                            <td className={"align-middle text-center"}>
                                                <span>{product.count}</span>
                                            </td>
                                            <td className={"align-middle text-right"}>
                                                <span>{product.price * product.count}€</span>
                                            </td>
                                        </tr>
                                    })
                                }
                                <tr className={"border-top"}>
                                    <th scope="row"></th>
                                    <td></td>
                                    <td></td>
                                    <td>PVM:</td>
                                    <td className={"text-right"}>21%</td>
                                </tr>
                                <tr>
                                    <th scope="row"></th>
                                    <td></td>
                                    <td></td>
                                    <td>Pristatymas:</td>
                                    <td className={"text-right"}>0€</td>
                                </tr>
                                <tr>
                                    <th scope="row"></th>
                                    <td colSpan="2"></td>
                                    <td>Viso:</td>
                                    <td className={"text-right"}>{this.calculateInitPrice().toFixed(2)}€</td>
                                </tr>
                                </tbody>
                            </table>

                        </small>
                        <small>
                            <table className="table table-sm table-borderless text-muted mt-5">
                                <tbody>
                                <tr>
                                    <td style={{width: "50%"}}>
                                        <p className={'m-0 p-0 text-dark'}>Informacija apie gavėją</p>
                                    </td>
                                    <td style={{width: "50%"}}
                                        className={'text-right'}>
                                        <p className={'m-0 p-0 text-dark'}>Informacija apie pardavėją</p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </small>

                        <hr/>

                        <small>
                            <table className="table table-borderless order-summary-tbl text-muted mt-2">
                                <tbody>
                                <tr>
                                    <td style={{width: "50%"}}>
                                        <h6 className={"font-weight-normal"}>
                                            Vardas Pavardė: {this.state.customer.name} {this.state.customer.lastName}
                                        </h6>
                                    </td>
                                    <td style={{width: "50%"}}
                                        className={'text-right'}>
                                        <h6 className={"font-weight-normal"}>Tiekėjas: PLACENTAL</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        El. paštas: {this.state.customer.email}
                                    </td>
                                    <td className={'text-right'}>
                                        El. paštas: info@placental.lt
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Tel. numeris: {this.state.customer.phone}
                                    </td>
                                    <td className={'text-right'}>
                                        Tel. numeris: +370 (616) 00 533
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Adresas, pašto kodas: {this.state.customer.address},
                                        {this.state.customer.postalCode},
                                        {this.state.customer.street}
                                    </td>
                                    <td className={'text-right'}>
                                        Adresas, pašto kodas: Algirdo g. 46-309, LT-03209 Vilnius
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Miestas: {this.state.customer.city}
                                    </td>
                                    <td className={'text-right'}>
                                        Miestas: Vilnius
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Šalis: {this.state.customer.country}
                                    </td>
                                    <td className={'text-right'}>
                                        Šalis: Lietuva
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td className={'text-right'}>
                                        Bankas: AB SEB Bankas
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td className={'text-right'}>
                                        Atsiskaitomoji sąskaita: LT74 7044 0600 0829 1102
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td className={'text-right'}>
                                        Mokėjimo paskirtyje galite nurodyti sąskaitos numerį, užsakymo numerį arba produkto
                                        pavadinimą, pavyzdžiui "už Placental kremą"
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </small>
                    </div>
                </div>
                <div className={"row mt-4 pl-3 mb-2"}>
                    <div className={"col-12"}>
                        <p style={{"fontSize": "15px"}}>
                            Mokėjimo metodas:
                            <span className={"text-primary ml-2"}>
                                 { this.props.paymentMethod }
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(OrderSummary)