import React from "react";
import {getUrls} from "../Config";
import {getJwt, infoMessageToast} from '../Helper';
import axios from "axios";

class UserProfile extends React.Component{
    constructor(props){
        super(props);
        this.state={
            id: null,
            email: null,
            username: null,
            name: null,
            lastName: null,
            phone: null,
            country: null,
            city: null,
            street: null,
            postalCode: null,
            error: false,
            errorMsg: null,
            message: null,
            success: false,
            placeholder: "Duomenų nėra.",
            passwordUpdateSuccess: false,
            passwordUpdateSuccessMessage: "Slaptažodis sėkmingai pakeistas.",
            passwordMismatch: false,
            passwordMismatchMessage: "Slaptažodžiai nesutampa.",
            password: null,
            passwordNew: null,
            passwordValid: false,
            urls: getUrls(),
            modal: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
    }

    toggleModal = () => {
        let currentState = this.state.modal;

        this.setState({
            modal: !currentState
        });

        if (this.state.modal === true){
            this.getUserDetails();
        }
    };

    handleChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };

    handlePasswordChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            passwordUpdateSuccess: false
        })

        this.setState({
            [name]: value
        }, () => {
            this.validateNewPassword(this.state.password, this.state.passwordNew);
        });
    };

    updateUser = () => {
        const jwt = getJwt();
        axios.patch(this.state.urls.environment.development + this.state.urls.apiVersion
            + this.state.urls.user.patch + this.state.id, {
                email: this.state.email,
                username: this.state.username,
                name: this.state.name,
                lastName: this.state.lastName,
                phone: this.state.phone,
                country: this.state.country,
                city: this.state.city,
                street: this.state.street,
                postalCode: this.state.postalCode
        }, {
                headers: {
                    'Authorization': jwt
                }
            }

        ).then(
            (res) => {
                infoMessageToast("Duomenys sėkmingai atnaujinti.", "fa-check-circle");
            }
        ).catch(
            (err) => {
                console.log(err);
                alert('Vartoto atnaujinti nepavyko, mėgikite dar kartą');
            }
        );
    }

    passwordUpdateSuccessCallback = () => {
        this.setState({
            passwordUpdateSuccess: true,
            password: "",
            passwordNew: "",
            passwordValid: false
        });
    }

    updatePassword = () => {
        const jwt = getJwt();
        axios.post(
            this.state.urls.environment.development +
            this.state.urls.apiVersion +
            this.state.urls.user.updatePassword, {
            passwordNew: this.state.password,
            passwordConfirm: this.state.passwordNew
        },{
            headers: {
                'Authorization': jwt
            }
        }).then(
            (res) => {
                this.passwordUpdateSuccessCallback();
            }
        ).catch(
            (err) => {
                localStorage.clear();
            }
        );
    }

    validateNewPassword = (password, newPassword) => {
        // If fields are empty setting validation ti initial state
        if(password === "" && newPassword === ""){
            this.setState({
                passwordValid: false
            });

            return;
        }

        this.setState({
            passwordValid: password === newPassword,
            passwordMismatch: password !== newPassword
        });
    }

    getUserDetails = () => {
        let jwt = getJwt();
        axios.get(this.state.urls.environment.development + this.state.urls.apiVersion + this.state.urls.user.profile, {
            'headers': { 'Authorization': jwt }
        }).then(
            (res) => {
                this.setState({
                    id: res.data.id,
                    email: res.data.email,
                    username: res.data.username,
                    name: res.data.name,
                    lastName: res.data.lastName,
                    phone: res.data.phone,
                    country: res.data.country,
                    city: res.data.city,
                    street: res.data.street,
                    postalCode: res.data.postalCode
                });
            }
        ).catch(
            (err) => {
                console.log(err);
            }
        );
    }

    componentDidMount() {
        this.getUserDetails();
    }

    render() {
        return (
            <div className={"content"}>
                <div className={this.state.modal ? 'placental-modal': 'd-none'} >
                    <button type="button" className="close pt-5 pr-5" aria-label="Close"
                            onClick={this.toggleModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div className={"row"}>
                        <div className={"col-md-2"}></div>
                        <div className={"col-md-8 pb-5"}>
                            <h2 className="font-weight-light mb-4 pl-5 mt-5">Redaguoti profilį</h2>
                            <div className={"p-5"}>
                                <div className="form-group">
                                    <label>Elektroninio pašto adresas</label>
                                    <input type="email"
                                           name="email"
                                           className="form-control"
                                           placeholder="Elektroninio pašto adresas"
                                           value={this.state.email}
                                           onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Vardas</label>
                                    <input type="text"
                                           className="form-control"
                                           name="name"
                                           placeholder="Vardas"
                                           value={this.state.name}
                                           onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Pavardė</label>
                                    <input type="text"
                                           className="form-control"
                                           name="lastName"
                                           placeholder="Pavardė"
                                           value={this.state.lastName}
                                           onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Telefono numeris</label>
                                    <input type="text"
                                           className="form-control"
                                           name="phone"
                                           placeholder="Telefono numeris"
                                           value={this.state.phone}
                                           onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Šalis</label>
                                    <input type="text"
                                           className="form-control"
                                           name="country"
                                           placeholder="Šalis"
                                           value={this.state.country}
                                           onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Miestas</label>
                                    <input type="text"
                                           className="form-control"
                                           name="city"
                                           placeholder="Miestas"
                                           value={this.state.city}
                                           onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Adresas
                                        <small className={"text-muted text-right pl-3"}>Gatvė, namo numeris, buto numeris</small>
                                    </label>
                                    <input type="text"
                                           className="form-control"
                                           name="street"
                                           placeholder="Adresas"
                                           value={this.state.street}
                                           onChange={this.handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Pašto kodas</label>
                                    <input type="text"
                                           className="form-control"
                                           name="postalCode"
                                           placeholder="Pašto kodas"
                                           value={this.state.postalCode}
                                           onChange={this.handleChange}
                                    />
                                </div>
                                <button type="button"
                                        onClick={this.updateUser}
                                        className="btn btn-sm btn-primary float-right">
                                    Išsaugoti</button>
                            </div>

                            <h2 className="font-weight-light mb-2 mt-5 pl-5 text-danger">Slaptažodžio ketimas</h2>
                            <div className={"p-5"}>
                                <div className="form-group p-5 border border-danger rounded-lg">

                                    <div  className={this.state.passwordUpdateSuccess ? 'alert alert-success': 'd-none'}
                                          role="alert"><small>
                                        <i className="fa fa-check fa-lg mr-2"></i>
                                        {this.state.passwordUpdateSuccessMessage}
                                    </small>
                                    </div>

                                    <div  className={this.state.passwordMismatch ? 'alert alert-danger': 'd-none'}
                                          role="alert"><small>
                                        <i className="fa fa-exclamation-circle fa-lg mr-1"></i>
                                        {this.state.passwordMismatchMessage}
                                    </small>
                                    </div>

                                    <label>Naujas slaptažodis</label>
                                    <input type="text"
                                           className="form-control"
                                           name="password"
                                           placeholder="Naujas slaptažodis"
                                           value={this.state.password}
                                           onChange={this.handlePasswordChange}
                                    />

                                    <label className={"mt-3"}>Pakartoti naują slaptažodį</label>
                                    <input type="text"
                                           className="form-control"
                                           name="passwordNew"
                                           placeholder="Pakartoti naują slaptažodį"
                                           value={this.state.passwordNew}
                                           onChange={this.handlePasswordChange}
                                    />
                                    <button type="button"
                                            onClick={this.updatePassword}
                                            disabled={!this.state.passwordValid}
                                            className="btn btn-sm btn-danger mt-4 float-right">
                                        Pakeisti slaptažodį</button>
                                    <div className={"clearfix"}></div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-md-2"}></div>
                    </div>
                </div>
                <div className="row p-4">
                    <div className="col-6">
                        <h3 className="font-weight-light mb-2">Vartotojo zona</h3>
                    </div>

                    <div className="col-6 text-right user-zone">
                        <button type="button"
                                className="btn btn-link"
                                onClick={this.toggleModal}>
                            <span className={'fa fa-pen fa-lg '}>&nbsp;</span>Redaguoti
                        </button>
                    </div>
                </div>

                <div className="row p-4">
                    <div className="text-muted col-sm">
                        <h4 className="font-weight-light mb-2 text-dark">{this.state.name} {this.state.lastName}</h4>

                        <div class="mb-5">
                            <i className="fa fa-star pr-2 text-warning" aria-hidden="true"></i>
                            <i className="fa fa-star pr-2" aria-hidden="true"></i>
                            <i className="fa fa-star pr-2" aria-hidden="true"></i>
                            <i className="fa fa-star pr-2" aria-hidden="true"></i>
                            <i className="fa fa-star pr-2" aria-hidden="true"></i>
                        </div>
                    </div>

                    <div className={"col-sm"}>
                        <h4 className="font-weight-light mb-5 text-dark">Bendri duomenys</h4>

                        <h5 className="font-weight-light mb-2 text-dark">Vartotojo vardas</h5>
                        <p>{this.state.username}</p>

                        <h5 className="font-weight-light mb-2 pt-3 text-dark">Elektroninio pašto adresas</h5>
                        <p>{this.state.email === null || this.state.email === "" ? this.state.placeholder : this.state.email }</p>

                        <h5 className="font-weight-light mb-2 pt-3 text-dark">Telefono numeris</h5>
                        <p class="mb-5">{this.state.phone === null || this.state.phone === "" ? this.state.placeholder : this.state.phone}</p>
                    </div>

                    <div className="col-sm">
                        <h4 className="font-weight-light mb-5 text-dark">Adresas</h4>

                        <h5 className="font-weight-light mb-2 text-dark">Šalis</h5>
                        <p>{this.state.country === "" || this.state.country === null ? this.state.placeholder : this.state.country}</p>

                        <h5 className="font-weight-light mb-2 pt-3 text-dark">Miestas</h5>
                        <p>{this.state.city === "" || this.state.city === null ? this.state.placeholder : this.state.city}</p>

                        <h5 className="font-weight-light mb-2 pt-3 text-dark">Adresas</h5>
                        <p>{this.state.street === "" || this.state.street === null ? this.state.placeholder : this.state.street}</p>

                        <h5 className="font-weight-light mb-2 pt-3 text-dark">Pašto kodas</h5>
                        <p>{this.state.postalCode === "" || this.state.postalCode === null ? this.state.placeholder : this.state.postalCode}</p>


                    </div>
                </div>
            </div>
        )
    }
}

export default UserProfile