import React from "react";
import {NavLink, withRouter} from "react-router-dom";

class AlertWarningUserDataNotValid extends React.Component{
    render() {
        return (
            <div className={'row p-4'}>
                <div className={'col-12'}>
                    <div className="alert alert-warning"
                         role="alert">Norėdami tęsti supildykite kontaktinius duomenis<NavLink className={"btn btn-sm btn-warning ml-5"}
                                 to={'/profile'}>Vartotojo zona</NavLink>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(AlertWarningUserDataNotValid)