import React from "react";
import {withRouter} from "react-router-dom";
import unicef from "../../images/unifec.png";

class TopMenu extends React.Component{
    render() {
        return (
            <div className={"bg-light top-menu w-100"}>
                <div className={"row pr-4 pl-4 pt-3 pb-3"}>
                    <div className={"col-12 text-center"}>
                        <img className={'mr-3'} src={unicef}
                             alt={'Placental cart item' }
                             width={'112px'} />
                        <p className={"d-inline-block p-0 m-0"}>
                            <a href="https://unicef.lt/paaukok-vaikams-2/"
                               target={"_blank"}>Paremkite nuo karo Ukrainoje nukentėjusias šeimas<span className="fa fa-angle-right" />
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(TopMenu)