import React, {useEffect, useState} from "react";
import {Alert, Col, Dropdown, message, Modal, Row, Spin, Table, Typography} from 'antd';
import axios from "axios";

import {getUrls} from '../Config';
import {DeleteOutlined, EllipsisOutlined} from "@ant-design/icons";

const { Text} = Typography;


function AllUsers(){

    const success = () => {
        message.success({
            type: 'success',
            content: 'Vartotojas ištrintas',
        });
    };

    const error = () => {
        message.error({
            type: 'error',
            content: 'Įvyko klaida',
        });
    };


    const [users, setUsers] = useState([]);
    const [urls, setUrls] = useState(getUrls());
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [spinning, setSpinning] = useState(false);

    const showLoader = () => {
        setSpinning(true);
    };

    const hideLoader = () => {
        setSpinning(false);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = async () => {
        setIsModalOpen(false);
        showLoader();
        await deleteUser(userToDelete);
    };

    const handleCancel = () => {
        setUserToDelete(null);
        setIsModalOpen(false);
    };

    const getAllUsers = () => {
        axios.get(
            urls.environment.development + urls.apiVersion + urls.user.get
        ).then((response) => {
            setUsers(response.data)
        }).catch(
            (err) => {
                console.log(err);
            }
        );
    };


    const deleteUser = async (id) => {
        const userDeleted = await axios.delete(
            urls.environment.development + urls.apiVersion + urls.user.delete + id, {
            headers: {
                'Content-Type': "text/plain"
            }
        })

        hideLoader();
        if (userDeleted.data === "OK"){
            success()
        }else{
            error()
        }

        getAllUsers()
    }

    const items = [
        {
            label: <Text
                onClick={"Testas"}>
                Peržiūrėti
            </Text>,
            key: '0',
        },
        {
            label: <Text
                    type="danger"
                    onClick={showModal}>
                        Ištrinti
                   </Text>,
            key: '1',
        },
    ]

    const dataSource = users;

    const dataHandler = (data)=>{
        return data ? data :
            <Text type="secondary">
                <small>No data</small>
            </Text>;
    }

    const columns = [
        // {
        //     title: 'Username',
        //     dataIndex: 'username',
        //     key: 'username',
        //     render: (username) => dataHandler(username)
        // },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name) => dataHandler(name)
        },
        {
            title: 'Last name',
            dataIndex: 'lastName',
            key: 'lastName',
            render: (lastName) => dataHandler(lastName)
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (email) => dataHandler(email)
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            render: (phone) => dataHandler(phone)
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            render: (country) => dataHandler(country)
        },
        // {
        //     title: 'City',
        //     dataIndex: 'city',
        //     key: 'city',
        //     render: (city) => dataHandler(city)
        // },
        // {
        //     title: 'Street',
        //     dataIndex: 'street',
        //     key: 'street',
        //     render: (street) => dataHandler(street)
        // },
        // {
        //     title: 'Postal code',
        //     dataIndex: 'postalCode',
        //     key: 'postalCode',
        //     render: (postalCode)=>{
        //         return postalCode ? postalCode :
        //             <Text type="secondary">
        //                 <small>No data</small>
        //             </Text>;
        //     }
        // },
        {
            title: 'Actions',
            dataIndex: 'id',
            key: 'id',
            render: (_, { id }) =><>
                <Dropdown
                    menu={{
                        items,
                        onClick: ({ key }) => {
                            setUserToDelete(id)
                        }
                }} trigger={['click']}>
                        <EllipsisOutlined />
                </Dropdown>
            </>
        },
    ];

    useEffect(() => {
        getAllUsers()
    }, []);
    return (
        <>
            <Row style={{
                maxWidth:"1024px",
                margin: "0 auto",
                textAlign: "center"
            }}>
                <Col
                    span={24}
                    style={{padding: 48}}
                >
                    <h3 className="font-weight-light mb-5 text-left">Vartotojai</h3>
                    <Spin spinning={spinning} tip={"Vykdoma..."} >
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        size="small"
                    />

                        <Modal
                            title="Vartotojo trinimo patvirtinimas"
                            open={isModalOpen}
                            onOk={handleOk} onCancel={handleCancel}>

                            <Alert
                                className={"mt-3"}
                                message="Šis veiksmas yra negrįžtamas"
                                type="error"
                                showIcon
                            />

                            <p className={"mt-4 mb-5"}>Patvirtinkite vartotojo trinimą. Patvirtinus operacija negali būti atšaukta.</p>

                        </Modal>
                    </Spin>

                </Col>
            </Row>
        </>
    )
}

export default AllUsers