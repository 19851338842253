import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import BreadCrumbIcon from "../helpers/BreadCrumbIcon";

class ReturnGoods extends React.Component{
    render() {
        return (
            <div className={"content"}>
                <div className={'row p-4'}>
                    <div className={'col-12 breadcrumbs mb-4'}>
                        <small>
                            <BreadCrumbIcon />
                            <NavLink className={'text-muted'}
                                     to={'/'}>Titulinis</NavLink>
                            <i className="fa fa-angle-right text-muted"></i>
                            <NavLink className={'text-muted ml-2'}
                                     to={'/return-goods'}>Prekių grąžinimas</NavLink>
                        </small>
                    </div>
                </div>
                <div className={'row p-4'}>
                    <div className={'col-12'}>
                        <h3 className="font-weight-light mb-2">Prekių grąžinimas</h3>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Grąžinimo taisyklės</h4>
                        <h6 className="font-weight-light"
                            style={{"line-height":"1.6", "text-indent": "24px"}}>
                            Jeigu gavus ir išpakavus prekę pastebite pažeistą pakuotę ar prekės broką, susisiekite su
                            mumis el.paštu info@placental.lt arba telefonu +370 (616) 00 533. Brokuotą ar pažeistą prekę
                            pakeisime kita arba grąžinsime Jums pinigus.</h6>
                        <h6 className="font-weight-light"
                            style={{"line-height":"1.6", "text-indent": "24px"}}>Grąžinti
                            prekes, remiantis „Daiktų grąžinimo ir keitimo taisyklėmis“, patvirtintomis 2001 m. birželio
                            29 d. ūkio ministro įsakymu Nr. 217
                            (http://www3.lrs.lt/pls/inter3/dokpaieska.showdoc_l?p_id=141450 ) bei ūkio ministro 2001 m.
                            rugpjūčio 17 d. įsakymu Nr. 258 patvirtintomis „Daiktų pardavimo ir paslaugų teikimo, kai
                            sutartys sudaromos naudojant ryšio priemones, taisyklėmis“
                            (http://www3.lrs.lt/pls/inter3/dokpaieska.showdoc_l?p_id=148061&p_query=&p_tr2= ), galite
                            per keturiolika darbo dienų nuo prekių pristatymo dienos, raštu pranešdami apie tai el.paštu
                            info@placental.lt. Įsigytą kokybišką prekę galima grąžinti per 14 d.d. jei: <br/><br/>

                            <ul>
                                <li>grąžinama prekė yra originalioje tvarkingoje pakuotėje</li>
                                <li>prekė yra nenaudota ir nesugadinta</li>
                                <li>prekė yra nepraradusi prekinės išvaizdos (nepažeistos etiketės, nenuplėštos
                                    apsauginės plėvelės ir kt.)</li>
                            </ul>
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Grąžinimo išlaidų apmokėjimas</h4>
                        <h6 className="font-weight-light"
                            style={{"line-height":"1.6", "text-indent": "24px"}}>Dengiame
                            visas grąžinimo išlaidas, per 30 kalendorinių dienų, jei prekė nenaudota ir nepažeista.</h6>
                        <h6 className="font-weight-light"
                            style={{"line-height":"1.6", "text-indent": "24px"}}>Tuo
                            atveju, jei prekė, kurią grąžinate, bus atidaryta, naudota ar bus pažeista gamintojo
                            pakuotė, prašysime pateikti paaiškinimą, kodėl taip įvyko ir ieškosime sprendimo kartu.</h6>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(ReturnGoods)