import React from "react";
import {NavLink} from "react-router-dom";
import placental_logo_secondary from "../images/placental_bg_gray.png";
import BreadCrumbIcon from "./helpers/BreadCrumbIcon";

class Contact extends React.Component{
    render() {
        return (
            <div className={"content"}>
                <div className={'row p-4'}>
                    <div className={'col-12 breadcrumbs mb-4'}>
                        <small>
                            <BreadCrumbIcon />
                            <NavLink className={'text-muted'}
                                     to={'/'}>Titulinis</NavLink>
                            <i className="fa fa-angle-right text-muted"></i>
                            <NavLink className={'text-muted ml-2'}
                                     to={'/contacts'}>Kontaktai</NavLink>
                        </small>
                    </div>
                </div>
                <div className="row p-4">
                    <div className="col-12">
                        <h3 className="font-weight-light">Kontaktai</h3>

                        <h4 className="font-weight-light mt-5">Bendriniai klausimai </h4>
                        <h6 className="mb-4 font-weight-light">El. Parduotuvė, pristatymas, bendra informacija, gydimas ir gydymo
                            programos.</h6>
                        <h6 className={"font-weight-light"} style={{"line-height":"1.6"}}>
                            Bendriniais klausimais visada galite kreiptis mūsų el. paštu info(eta)placental.lt, atsakome
                            greitai ir išsamiai. Jei pageidaujate bendrauti gyvai maloniai prašome prašyti mums žinutę mūsų
                            <a href="https://www.facebook.com/Placental-1589459521318203/"
                               target="_blank"
                               className={"d-inline-block mr-1 ml-1"}>
                                <h6 className={"mb-0"}>Facebook</h6>
                            </a>paskyroje. Atsiradus poreikiui galite skambinti mob. telefonu +370 (616) 00 533. Drąsiai
                            kreipkitės į mus Jums patogiausiu kanalu.
                        </h6>

                        <h4 className="font-weight-light mt-5">Specializuoti klausimai</h4>
                        <h6 className="mb-4 font-weight-light">Gamyba, komerciniai pasiūlymai, sertifikatai</h6>
                        <h6 className={"font-weight-light"}
                            style={{"line-height":"1.6"}}>
                            Jei Jūs esate profesionalas, domitės mūsų produkcija savo klinikai ar planuojate didmeninę
                            prekybą, maloniai prašome skambinti mob. telefonu +370 (622) 11 393. Jei norite bendrauti
                            ne telefonu, maloniai prašome kreiptis mūsų el. paštu info(eta)placental.lt ir Jūsų
                            paklausimą nukreipsime pas atitinkamą specialistą.
                        </h6>

                        <h4 className="font-weight-light mt-5">Rekvizitai </h4>
                        <h6 className="mb-4 font-weight-light">Mokėjimai, korespondencija, kiti klausimai</h6>
                        <h6 className="font-weight-light">Placental rekvizitus rasite mūsų <NavLink
                            to={'/company-details'}><h6 className={'font-weight-light d-inline-block'}>"Rekvizitai"</h6>
                        </NavLink> puslapyje.</h6>
                    </div>
                    <div className={'col-sm-2'}>&nbsp;</div>
                </div>
            </div>
        )
    }
}

export default Contact