import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import BreadCrumbIcon from "../helpers/BreadCrumbIcon";

class CreamComposition extends React.Component{
    render() {
        return (
            <div className={"content"}>
                <div className={'row p-4'}>
                    <div className={'col-12 breadcrumbs mb-4'}>
                        <small>
                            <BreadCrumbIcon />
                            <NavLink className={'text-muted'} to={'/'}>Titulinis</NavLink>
                            <i className="fa fa-angle-right text-muted"></i>
                            <NavLink className={'text-muted ml-2'} to={'/cream-composition'}>Placental kremo sudėtis</NavLink>
                        </small>
                    </div>
                </div>
                <div className={'row p-4'}>
                    <div className={'col-12'}>
                        <h3 className="font-weight-light mb-2">Placental kremo sudėtis</h3>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Apie Placental kremą</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Placental kremas su placentos ekstraktu, hialurono rūgštimi ir D-pantenoliu. Padeda
                            maitinti ir drėkinti veido odą. Sudėtyje esantis placentos ekstraktas pasižymi
                            balandsuojančiomis savybėmis. D-pantenolis žinomas kaip odą raminantis komponentas, o
                            hialurono rūgštis - puiki priemonė sprendžiant odos dehidratacijos problemas.
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Placental naudojimas</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>Tepti švarią veido
                            odą ryte ir vakare. Švelniai įmasažuoti į veido ir dekolte odą (galima tepti ir paakių
                            zoną), palaukti, kol įsigers. Padeda drėkinti ir maitinti veido odą. Laikymo sąlygos: +5 -
                            +25 C.
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Sudėtis</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            AQUA, PLACENTAL PROTEIN, CETEARYL ALCOHOL, GLYCERIN, CAPRYLIC/CAPRIC, TRIGLYCERIDE, UNDECANE,
                            TRIDECANE, ARACHIDYL ALCOHOL, BEHENYL ALCOHOL, ARACHIDYL GLUCOSIDE, SODIUM HYALURONATE,
                            PANTHENOL, TOCOPHERYL ACETATE, XANTHAN GUM, PHENETHYL ALCOHOL, CAPRYLYL GLYCOL, PARFUM,
                            CITRIC ACID
                        </h6>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(CreamComposition)