import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {authenticate} from './Helper'

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest}
        render={(props) => (
            authenticate()
            ? <Component {...props}/>
            : <Redirect to='/login'/>
        )}
    />
);

export default PrivateRoute