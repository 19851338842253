import React from "react";
import {NavLink} from "react-router-dom";

class Error extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount(){}

    render() {
        return (
            <div className={"mt-5 mb-5 pt-5 pb-5"}>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                    <circle className="path circle" fill="none" stroke="#f53739" stroke-width="6" stroke-miterlimit="10"
                            cx="65.1" cy="65.1" r="62.1"/>
                    <line className="path line" fill="none" stroke="#f53739" stroke-width="6" stroke-linecap="round"
                          stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                    <line className="path line" fill="none" stroke="#f53739" stroke-width="6" stroke-linecap="round"
                          stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
                </svg>
                <h3 className="font-weight-light">Įvyko klaida</h3>
                <p className={"pt-3"}>Prašome mėginti dar kartą arba kreipkitės į mus nurodytais kontaktais. </p>
                <NavLink to={'/'}
                         className={"btn btn-sm btn-custom-success"}>
                    Bandyti dar kartą
                </NavLink>
            </div>
        )
    }
}

export default Error