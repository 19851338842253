import React from "react";
import axios from "axios";
import {getUrls} from "./Config";

class PaymentAccepted extends React.Component{
    constructor(props){
        super(props);
        this.state={
            data: {},
            urls: getUrls(),
        }
    }

    getPaymentDetails = () => {
        let encoded = this.props.match.params.encoded;
        // console.log(this.state.urls.environment.development + this.state.urls.apiVersion + this.state.urls.opay.paymentDone + encoded);

        axios.get(this.state.urls.environment.development + this.state.urls.apiVersion + this.state.urls.opay.paymentDone + encoded).then(
            (response) => {
                this.setState({
                    data: response.data
                });
            }
        ).catch(
            (err) => {
                this.errorMessageHandler();
                console.log(err);
            }
        );
    }

    componentDidMount() {
        this.getPaymentDetails();
    }

    render() {
        return (
            <div className={"content"}>
                <div className={'row p-4'}>
                    <div className="col-12">
                        <h3 className={'font-weight-light mb-2'}>Mokėjimas priimtas</h3>
                        <p className={'mb-5 text-muted'}>Mokėjimas įvykdytas.</p>
                    </div>
                </div>

                <div className={'row p-3'}>
                    <div className="col-3"></div>
                    <div className="col-6">
                        <div className="alert alert-custom-success"
                             role="alert">
                            {this.state.data.status}
                        </div>
                    </div>
                    <div className="col-3"></div>
                </div>
            </div>
        )
    }
}

export default PaymentAccepted