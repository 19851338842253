import React from "react";
import { withRouter } from "react-router-dom";

class PageNotFound extends React.Component{
    render() {
        return (
            <div className={"content"}>
                <div className={'row p-4'}>
                    <div className="col-12">
                        Page not found
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(PageNotFound)