import React from "react";
import axios from "axios";
import {getUrls} from '../Config';
import {NavLink} from "react-router-dom";

class Register extends React.Component{

    constructor(props){
        super(props);
        this.state={
            urls: getUrls(),
            username: '',
            password: '',
            email: '',
            validForm: false,
            passwordError: '',
            usernameError: '',
            registrationCompleted: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    getJwt = () => {
        return  localStorage.getItem('jwt-access');
    };

    componentDidMount() {
        if (this.getJwt()){
            this.props.history.push('/');
        }
    }

    handleChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, () => this.validate(name));
    };

    submit = (e) => {
        e.preventDefault();
        if(this.state.username === ''){
            this.setState({
                usernameError: 'Vartotojo vardas privalomas'
            });
        }

        if(this.state.password === ''){
            this.setState({
                passwordError: 'Slaptažodis privalomas'
            });
        }

        if(this.state.email === ''){
            this.setState({
                emailError: 'El. pašto adresas privalomas'
            });
        }

        if(this.state.password === '' || this.state.username === '' || this.state.email === ''){
            return;
        }

        axios.post(this.state.urls.environment.development + this.state.urls.apiVersion + this.state.urls.user.register, {
            username: this.state.username,
            password: this.state.password,
            email: this.state.email,
            roles: "USER",
            active: 0,
        }).then(
            (res) => {
                localStorage.clear();
                this.setState({registrationCompleted: true})
            }
        ).catch(
                (error) => {
                    if (!error.request){
                        if(error.response.status === 403){
                            this.setState({
                                authenticationError: 'Vartotojas su tokiais duomenimis jau egzistuoja.'
                            })
                        }else {
                            this.setState({
                                authenticationError: 'Įvyko klaida, mėginkite dar kartą vėliau.'
                            })
                        }
                    }else{
                        if(error.request.status === 403){
                            this.setState({
                                authenticationError: 'Vartotojas su tokiais duomenimis jau egzistuoja'
                            })
                        }else{
                            this.setState({
                                authenticationError: 'Sistemos gedimas. Bandykite vėliau.'
                            })
                        }
                    }
                    localStorage.clear();
                }
        );
    };

    validate = (inputName) => {
        let nameErrorMessage = 'Vardo laukas yra privalomas';
        let lastNameErrorMessage = 'Pavardės laukas yra privalomas';
        let emailErrorMessage = 'El. pašto adresas neteisingas';
        let usernameErrorMessage = 'Vartotojo vardas privalomas';
        let phoneErrorMessage = 'Telefono numerio laukas privalomas';
        let addressErrorMessage = 'Addreso laukas privalomas';
        let postalCodeErrorMessage = 'Pašto kodo laukas privalomas';
        let cityErrorMessage = 'Miesto laukas privalomas';
        let countryErrorMessage = 'Šalies laukas privalomas';
        let passwordErrorMessage = 'Slaptažodis privalomas';
        let ErrorMessage = eval(inputName + 'ErrorMessage');
        let error = inputName + 'Error';
        let valid = inputName + 'Valid';

        if(inputName === 'email'){
            this.state[inputName] && this.state.email.includes('@') ? this.setState({
                [error]: '',
                [valid]: true
            },() => this.allowRegister()) : this.setState({
                [error]: ErrorMessage,
                [valid]: false
            },() => this.allowRegister());
        } else {
            this.state[inputName] ? this.setState({
                [error]: '',
                [valid]: true
            }, () => this.allowRegister()) : this.setState({
                [error]: ErrorMessage,
                [valid]: false
            }, () => this.allowRegister());
        }
    };

    allowRegister = () => {
        let result = [];
        for (let prop in this.state) {
            if (Object.prototype.hasOwnProperty.call(this.state, prop)) {
                if(prop.includes('Valid') && !prop.includes('form')){
                    result.push(this.state[prop])
                }
            }
        }
        this.setState({validForm: result.includes(false) || result.length !== 3 ? false : true});
    };

    render() {
        return (
                <div className={'row p-4'}>
                    <div className={'col-lg-3'}>&nbsp;</div>
                    <div className={'col-lg-6'}>
                        <h3 className="font-weight-light mb-2 pl-5">Registracija</h3>

                        <div className={this.state.registrationCompleted ? '' : 'd-none'}>
                            <h6 className={"pl-5 mt-5 font-weight-light"}>
                            <span className="fa fa-check fa-lg text-success d-inline-block mr-2"></span>Dėkojame!</h6>
                            <p className={"pl-5 mt-3 font-weight-light"}>Registracijos patvirtinimo el. laiškas
                                išsiųstas Jūsų pateiktu el. pašto adresu.</p>
                        </div>
                        <div className={this.state.registrationCompleted ? 'd-none' : ''}>
                            <form className={'p-5 m-5 shadow'} >
                                <small className={'text-danger mb-3 d-inline-block'}>
                                    {this.state.authenticationError}</small>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Vartotojo vardas</label>
                                    <input type="text"
                                           name={'username'}
                                           className="form-control"
                                           id="exampleInputEmail1"
                                           placeholder="Vartotojo vardas"
                                           onChange={this.handleChange}
                                           />
                                    <small className={'text-danger'}>{this.state.usernameError}</small>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">El. pašto adresas</label>
                                    <input type="email"
                                           className="form-control"
                                           id="exampleInputEmail1"
                                           name="email"
                                           placeholder="El. pašto adresas"
                                           onChange={this.handleChange}
                                    />
                                    <small className={'text-danger'}>{this.state.emailError}</small>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Slaptažodis</label>
                                    <input type="password"
                                           className="form-control"
                                           id="exampleInputPassword1"
                                           name="password"
                                           placeholder="Slaptažodis"
                                           onChange={this.handleChange}
                                    />
                                    <small className={'text-danger'}>{this.state.passwordError}</small>
                                </div>
                                <button disabled={!this.state.validForm}
                                        type="submit"
                                        onClick={this.submit}
                                        className="btn btn-primary mt-3">Registruotis</button>
                            </form>
                        </div>
                    </div>
                    <div className={'col-lg-3'}>&nbsp;</div>
                </div>
        );
    }
}

export default Register