import React from "react";
import {NavLink} from "react-router-dom";
import placentalSerum from "../../images/placentalSerumIcon.jpg";
import placentalCream from "../../images/placentalCreamIcon.jpg";
import placentalSet from "../../images/placentalSetIcon.jpg";
import exosomesSerumIcon from "../../images/exosomesSerumIcon.jpg";
import creamSerumExosomes from "../../images/creamSerumExosomes.jpg";
import creamExosomes from "../../images/creamExosomes.jpg";
import serumExosomes from "../../images/serumExosomes.jpg";

class Masonry extends React.Component{
    render() {
        return (
            <div>
                <div className="row masonry m-0 p-3 bg-light">
                    <div className="col-md-4 p-3 text-center">
                        <div className={"bg-white p-4"}>
                            <img
                                src={creamSerumExosomes}
                                width={"100%"}
                                height={"100%"}
                            />
                            <h5 className={"font-weight-light mb-4 mt-4"}>
                                Exosomes & Cream & Serum
                            </h5>
                            <p className={"font-weight-light mb-4 mt-4"}>
                                349€
                                <small className={"text-muted"}> 1 vnt.</small>
                            </p>
                            <button
                                className="btn btn-sm btn-outline-dark pr-3 pl-3 "
                                onClick={() => this.props.add_to_cart("setExosomesCreamSerum")}
                            >
                                <span className="fa fa-cart-arrow-down mr-2"></span>
                                Pirkti
                            </button>
                        </div>
                    </div>
                    <div className="col-md-4 p-3 text-center">
                        <div className={"bg-white p-4"}>
                            <img
                                src={creamExosomes}
                                width={"100%"}
                                height={"100%"}
                            />
                            <h5 className={"font-weight-light mb-4 mt-4"}>
                                Cream & Exosomes
                            </h5>
                            <p className={"font-weight-light mb-4 mt-4"}>
                                259€
                                <small className={"text-muted"}> 1 vnt.</small>
                            </p>
                            <button
                                className="btn btn-sm btn-outline-dark pr-3 pl-3 "
                                onClick={() => this.props.add_to_cart("setExosomesCream")}
                            >
                                <span className="fa fa-cart-arrow-down mr-2"></span>
                                Pirkti
                            </button>
                        </div>
                    </div>
                    <div className="col-md-4 p-3 text-center">
                        <div className={"bg-white p-4"}>
                            <img
                                src={serumExosomes}
                                width={"100%"}
                                height={"100%"}
                            />
                            <h5 className={"font-weight-light mb-4 mt-4"}>
                                Serum & Exosomes
                            </h5>
                            <p className={"font-weight-light mb-4 mt-4"}>
                                279€
                                <small className={"text-muted"}> 1 vnt.</small>
                            </p>
                            <button
                                className="btn btn-sm btn-outline-dark pr-3 pl-3 "
                                onClick={() => this.props.add_to_cart("setExosomesSerum")}
                            >
                                <span className="fa fa-cart-arrow-down mr-2"></span>
                                Pirkti
                            </button>
                        </div>
                    </div>
                </div>


                <div className="row masonry m-0 p-3 bg-light">
                    <div className="col-md-3 p-3 text-center">
                        <div className={"bg-white p-4"}>
                            <img
                                src={exosomesSerumIcon}
                                width={"100%"}
                                height={"100%"}
                            />
                            <h5 className={"font-weight-light mb-4 mt-4"}>
                                Exosomes Face Serum
                            </h5>
                            <p className={"font-weight-light mb-4 mt-4"}>
                                199€
                                <small className={"text-muted"}> 1 vnt.</small>
                            </p>
                            <button
                                className="btn btn-sm btn-outline-dark pr-3 pl-3 "
                                onClick={() => this.props.add_to_cart("exosomes")}
                            >
                                <span className="fa fa-cart-arrow-down mr-2"></span>
                                Pirkti
                            </button>
                        </div>
                    </div>
                    <div className="col-md-3 p-3 text-center">
                        <div className={"bg-white p-4"}>
                            <img
                                src={placentalCream}
                                width={"100%"}
                                height={"100%"}
                            />
                            <h5 className={"font-weight-light mb-4 mt-4"}>
                                Placental Face Cream
                            </h5>
                            <p className={"font-weight-light mb-4 mt-4"}>
                                119€
                                <small className={"text-muted"}> 1 vnt.</small>
                            </p>
                            <button
                                className="btn btn-sm btn-outline-dark pr-3 pl-3 "
                                onClick={() => this.props.add_to_cart("cream")}
                            >
                                <span className="fa fa-cart-arrow-down mr-2"></span>
                                Pirkti
                            </button>
                        </div>
                    </div>
                    <div className="col-md-3 p-3 text-center">
                        <div className={"bg-white p-4"}>
                            <img
                                src={placentalSerum}
                                width={"100%"}
                                height={"100%"}
                            />
                            <h5 className={"font-weight-light mb-4 mt-4"}>
                                Placental Face Serum
                            </h5>
                            <p className={"font-weight-light mb-4 mt-4"}>
                                139€
                                <small className={"text-muted"}> 1 vnt.</small>
                            </p>
                            <button
                                className="btn btn-sm btn-outline-dark pr-3 pl-3 "
                                onClick={() => this.props.add_to_cart("serum")}
                            >
                                <span className="fa fa-cart-arrow-down mr-2"></span>
                                Pirkti
                            </button>
                        </div>
                    </div>
                    <div className="col-md-3 p-3 text-center ">
                        <div className={"best-price"}>
                        </div>
                        <div className={"best-price-text"}>
                            <span className={"fa fa-heart"}></span>
                        </div>
                        <div className={"bg-white p-4"}>
                            <img
                                src={placentalSet}
                                width={"100%"}
                                height={"100%"}
                            />
                            <h5 className={"font-weight-light mb-4 mt-4"}>
                                Face Cream and Serum
                            </h5>
                            <p className={"font-weight-light mb-4 mt-4"}>
                                199€
                                <small className={"text-muted"}> 1 vnt.</small>
                            </p>
                            <button
                                className="btn btn-sm btn-outline-dark pr-3 pl-3 "
                                onClick={() => this.props.add_to_cart("set")}
                            >
                                <span className="fa fa-cart-arrow-down mr-2"></span>
                                Pirkti
                            </button>
                        </div>
                    </div>
                </div>

                <div className="row masonry m-0">
                    <div
                        className="col-md-6 p-5 text-center text-white"
                        style={{"background": "#000"}}
                    >
                        <h1 className={"mt-3"}>Naujiena! <br/>
                            <span className={"placental-title-big"}>Placental</span>serumas
                        </h1>
                        <h3 className={"font-weight-light mt-4 mb-4"}>
                            Veido serumas su placentos ekstraktu, hialurono<br/>rūgštimi ir peptidais
                        </h3>

                    </div>
                    <div
                        className="col-md-6 p-5 text-center"
                        style={{backgroundImage: `url(${placentalSerum})`,
                            backgroundPosition: `center`,
                            backgroundSize: `cover`,
                            backgroundRepeat: `no-repeat`,
                            minHeight: `320px`
                        }}
                    >
                    </div>
                </div>

                <div className="row mt-5 masonry m-0">
                    <div className="col-12 p-5 text-center">
                        <h1>Atsinaujinęs <span className={"placental-title-big"}>Placental</span> kremas</h1>
                        <h3 className={"font-weight-light mt-4"}>Toks pat efektyvus ir kupinas<br/>
                            naujų atspalvių </h3>
                        <NavLink to={'/changes'}
                                 className={"btn btn-lg btn-link font-weight-light"}>
                            Daugiau <small className="fa fa-angle-right"></small>
                        </NavLink>
                    </div>
                </div>

                <div className="row mt-5 masonry ml-0 mr-0">
                    <div className="col-md-6 p-5 gradient-background text-center">
                        <h1 className={"text-white mt-4 sh"}>Puikus efektas, geresnė kaina</h1>
                        <h3 className="font-weight-light mt-4 text-white">Naudok ilgiau, mokėk mažiau</h3>
                        <NavLink
                            to={'/wholesale'}
                            className={'btn btn-lg btn-link text-dark font-weight-light mt-3'}
                        >
                            Daugiau<small className="fa fa-angle-right"></small>
                        </NavLink>
                    </div>
                    <div
                        className="col-md-6 p-5 text-center"
                        style={{"background": "#000"}}
                    >
                        <h1 className={"text-white"}>
                            <span className="placental-title-big">Placental</span> goes black
                        </h1>
                        <h3 className={"font-weight-light mt-4 text-white"}>Visada unikalus dizainas</h3>
                        <NavLink
                            to={'/black'}
                            className={'btn btn-lg btn-link font-weight-light mt-3'}
                        >
                            Daugiau
                            <small className="fa fa-angle-right"></small>
                        </NavLink>
                    </div>
                </div>

                <div className={"row m-0"}>
                    <div className="col-12 p-5 text-center mt-5 ml-0 ">
                        <h1 className={'text-primary'}>
                            <span className={"placental-title-big"}>Placental
                            <h3 className={"d-inline-block"}>&nbsp;Social media</h3></span>
                            <br/>
                            <a
                                href="https://www.facebook.com/Placental-1589459521318203/"
                                target={"_blank"}
                                className={"btn btn-link font-weight-light"}
                            >
                                <h1>Facebook</h1>
                            </a>
                        </h1>
                        <h3 className={"font-weight-light mt-4"}>
                            Naujausia informacija, bendravimas ir<br/> dovanos
                        </h3>
                        <a
                            href="https://www.facebook.com/profile.php?id=100063540432217"
                            target={"_blank"}
                            className={"btn btn-link font-weight-light mt-3"}
                        >
                            <h1 className="la la-facebook">&nbsp;</h1>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Masonry