import React from "react";
import {NavLink} from "react-router-dom";

function CheckoutSuccess (props){
    return (
        <div className={"mt-5 mb-5 pt-5 pb-5"}>
            <div className="success-icon mb-3">
                <div className="success-icon__tip"></div>
                <div className="success-icon__long"></div>
            </div>
            <h3 className="font-weight-light">Ačiū už Jūsų užsakymą</h3>
            <p className={"pt-3"}>Visą informaciją išsiuntemė Jūsų nurodytų elektroninio pašto adresu.</p>


            <NavLink to={'/'} onClick={() => props.clearCart()}
                     className={"btn btn-sm btn-custom-success"}>
                Į pradžią
            </NavLink>
        </div>
    )
}

export default CheckoutSuccess