import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import BreadCrumbIcon from "../helpers/BreadCrumbIcon";

class Certificates extends React.Component{
    render() {
        return (
            <div className={"content"}>
                <div className={'row p-4'}>
                    <div className={'col-12 breadcrumbs mb-4'}>
                        <small>
                            <BreadCrumbIcon />
                            <NavLink className={'text-muted'}
                                     to={'/'}>Titulinis</NavLink>
                            <i className="fa fa-angle-right text-muted"></i>
                            <NavLink className={'text-muted ml-2'}
                                     to={'/certificates'}>Sertifikatai</NavLink>
                        </small>
                    </div>
                </div>
                <div className={'row p-4'}>
                    <div className={'col-12'}>
                        <h3 className="font-weight-light mb-2">Sertifikatai</h3>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Informacija ruošiama</h4>

                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>Šiuo metu tikriname sertifikatus. Dokumentuose yra
                            informacijos, kuri yra komercinė paslaptis. Kol renkame duomenis, kuriuos galėsime pateikti
                            viešai, prašome kreiptis nurodytais
                            <NavLink to={'/contacts'}>
                                <h6 className={"font-weight-light d-inline-block"}>kontaktais</h6>
                            </NavLink>, jei turite, klausimų susijusių su Placental kremo sertifikavimu.
                        </h6>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Certificates)