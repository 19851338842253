import React from "react";
import axios from "axios";
import {getUrls} from './Config';
import {NavLink} from "react-router-dom";

class Login extends React.Component{

    constructor(props){
        super(props);
        this.state={
            urls: getUrls(),
            username: '',
            password: '',
            validForm: false,
            passwordError: '',
            usernameError: '',
            authenticationError: '',
            spinner: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    getJwt = () => {
        return  localStorage.getItem('jwt-access');
    };

    componentDidMount() {
        if (this.getJwt()){
            this.props.history.push('/');
        }
    }


    handleChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, () => this.validate(name));
    };

    submit = (e) => {

        this.setState({
            spinner: true,
            validForm: false
        });

        e.preventDefault();

        if(this.state.username === ''){
            this.setState({
                usernameError: 'Vartotojo vardas privalomas',
                spinner: false
            });
        }

        if(this.state.password === ''){
            this.setState({
                passwordError: 'Slaptažodis privalomas',
                spinner: false
            });
        }

        if(this.state.password === '' || this.state.username === ''){
            this.setState({
                spinner: false,
                validForm: true
            });
            return;
        }
        axios.post(this.state.urls.environment.development + '/login', {
            username: this.state.username,
            password: this.state.password
        }).then(
            (res) => {
                localStorage.clear();
                localStorage.setItem('jwt-access', res.headers.authorization);
                this.props.setUser();
                this.setState({
                    spinner: false
                });
                setTimeout(
                    this.props.history.push('/'), 2000
                );

            }
        ).catch(
                (err) => {
                    localStorage.clear();
                    this.setState({
                        spinner: false,
                        validForm: true
                    });
                    this.setState({authenticationError: 'Netinakmas vartotojo vardas arba slaptažodis.'})
                }
        );
    };

    validate = (inputName) => {
        let usernameErrorMessage = 'Vartotojo vardas privalomas';
        let passwordErrorMessage = 'Slaptažodis privalomas';
        let ErrorMessage = eval(inputName + 'ErrorMessage');
        let error = inputName + 'Error';
        let valid = inputName + 'Valid';

        if(inputName in this.state) {
            this.state[inputName] ? this.setState({
                [error]: '',
                [valid]: true
            }, () => this.allowLogin()) : this.setState({[error]: ErrorMessage, [valid]: false}, () => this.allowLogin());

        }
    };

    allowLogin = () => {
        let result = [];

        for (let prop in this.state) {
            if (Object.prototype.hasOwnProperty.call(this.state, prop)) {
                if(prop.includes('Valid') && !prop.includes('form')){
                    result.push(this.state[prop])
                }
            }
        }
        this.setState({
            validForm: !(result.includes(false) || result.length !== 2)});
    };


    render() {
        return (
            <div className={'row p-4'}>
                <div className={'col-lg-3'}>&nbsp;</div>
                <div className={'col-lg-6'}>
                    <h3 className="font-weight-light mb-2 pl-5">Prisijungti</h3>
                    <form className={'p-5 m-5 shadow'} >
                        <small className={'text-danger mb-3 d-inline-block'}>
                            {this.state.authenticationError}</small>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Vartotojo vardas</label>
                            <input type="text"
                                   name={'username'}
                                   className="form-control"
                                   id="exampleInputEmail1"
                                   placeholder="Vartotojo vardas"
                                   onChange={this.handleChange}
                                   />
                            <small className={'text-danger'}>{this.state.usernameError}</small>
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Slaptažodis</label>
                            <input type="password"
                                   className="form-control"
                                   id="exampleInputPassword1"
                                   name="password"
                                   placeholder="Slaptažodis"
                                   onChange={this.handleChange}
                            />
                            <small className={'text-danger'}>{this.state.passwordError}</small>
                        </div>
                        <button disabled={!this.state.validForm} type="submit" onClick={this.submit}
                                className="btn btn-primary mt-3">Prisijungti
                            <div className={this.state.spinner ? 'd-inline-block spinner-border spinner-border-sm text-white ml-2' : 'd-none'}
                                 role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </button>
                        <NavLink className={"btn btn-link mt-3"}
                                 to={'/register'}>Registruotis</NavLink>

                        <NavLink className={"d-block mt-4 float-right text-muted"}
                                 to={'/password-reset'}>Pamiršote
                            slaptažodį?</NavLink><br/><br/>
                    </form>
                </div>
                <div className={'col-lg-3'}>&nbsp;</div>
            </div>
        );
    }
}

export default Login