import axios from "axios";
import {Button, Col, Result, Row, Spin} from 'antd';
import React, {useEffect, useState} from "react";
import {getUrls} from "../../Config";

function AuthPayment() {
    const [urls, setUrls] = useState(getUrls());
    const [progress, setProgress] = useState(true);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [paymentConfirmationError, setPaymentConfirmationError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Baigėsi sesija. Bandykite dar kartą.");

    const getCodeFromURI = async () => {
        const params = new URLSearchParams(window.location.search);
        return params.get("code");
    }

    const getToken = async () => {
        const code = await getCodeFromURI();
        const response = await axios.post(
            urls.environment.development + urls.payments.getToken,
            code,
            {
                headers: {
                    'Content-Type': "text/plain"
                }
            }
        ).catch(
            (error) => {
                console.log(error.message);

                setSuccess(false);
                setProgress(false);

                if(error.response.status === 500){
                    setPaymentConfirmationError(true);
                }

            }
        )

        return response.data.accessToken;
    }

    const initPayment = async () => {
        const token = await getToken();
        const orderNumber = localStorage.getItem("orderNr");
        const response = await axios.post(
            urls.environment.development + urls.payments.initiate + token,
            orderNumber,
            {
                headers: {
                    'Content-Type': "text/plain"
                }
            }
        ).catch(error.log);

        await createPayment(
            response.data.id, response.data.bankStatus, orderNumber, response.data.statusGroup
        ).catch(error.log);

        setProgress(false);
        window.location.replace(response.data.confirmLink)
    }

    const createPayment = async (paymentId, bankStatus, orderNumber, statusGroup) =>{
        const payment = {
            "paymentId": paymentId,
            "bankStatus": bankStatus,
            "orderNumber": orderNumber,
            "statusGroup": statusGroup,
        }
        const response = await axios.post(
            urls.environment.development + urls.payments.create, payment
        )
    }

    useEffect(() => {
        const payment = async ()=> {
            await initPayment();
        }
        payment().catch(
            (error) => {
                console.log(error);
                // setError(true);
            }

        );

    }, []);

    return (
        <>
            <Row style={{
                maxWidth:"1024px",
                margin: "0 auto",
                textAlign: "center"
            }}>
                <Col
                    span={24}
                    style={{padding: 48}}
                >
                    {progress &&
                        <Spin tip="Vykdome Jūsų autentifikaciją, prašome palaukti" size="large">
                            <div className="content" />
                        </Spin>
                    }

                    {success &&
                        <Result
                            status="success"
                            title="Autentifikacija sėkminga"
                            subTitle="Nukreipiame Jus tolimesniems veiksmams."
                            extra={[
                                <Button type="primary" key="console">
                                    Tikrinti
                                </Button>
                            ]}
                        />
                    }

                    {error &&
                        <Result
                            status="404"
                            title="Nepavyko Jūsų autentifikuoti."
                            subTitle="Bankas nepatvirtina Jūsų autntifikacijos"
                            extra={<Button type="primary">Užsakymas</Button>}
                        />
                    }

                    {paymentConfirmationError &&
                        <Result
                            status="500"
                            title="Klaida"
                            subTitle={errorMessage}
                            extra={<Button type="primary">Užsakymas</Button>}
                        />
                    }

                </Col>
            </Row>
        </>
    );


}

export default AuthPayment;