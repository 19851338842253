import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import BreadCrumbIcon from "../helpers/BreadCrumbIcon";

class SerumComposition extends React.Component{
    render() {
        return (
            <div className={"content"}>
                <div className={'row p-4'}>
                    <div className={'col-12 breadcrumbs mb-4'}>
                        <small>
                            <BreadCrumbIcon />
                            <NavLink className={'text-muted'} to={'/'}>Titulinis</NavLink>
                            <i className="fa fa-angle-right text-muted"></i>
                            <NavLink className={'text-muted ml-2'} to={'/serum-composition'}>Serumai</NavLink>
                        </small>
                    </div>
                </div>
                <div className={'row p-4'}>
                    <div className={'col-6'}>
                        <h3 className="font-weight-light mb-2">Placentinis serumas</h3>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Apie Placental serumą</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Jūsų rankose išskirtinis produktas! Veido serumas su placentos ekstraktu,  hialurono rūgštimi
                            ir dviejų rūšių peptidais. Ši priemonė puikiai tinka visų odos tipų grožio ritualams.
                            Placentos baltymai - ypatingas ingredientas, kuris gali veikti kaip maitinanti, drėkinanti,
                            skaistinanti ir raminanti priemonė Jūsų odai. Placental veikdo serumas inovatyvių
                            ingredientų kokteilis jūsų veido odai. Šis produktas praturtintas ne tik placentos baltymų
                            ekstraktų,tačiau ir hialurono rūgstimi, kuri žinoma, kaip vienas geriausių odos drėkiklių,
                            taip pat heksapeptidais ir pentapeptidais, kurie puikiai tinka brandžios odos priežiūrai.
                        </h6>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Ši priemonė - puikus būdas palepinti savo odą.
                            Veido serumas su placentos ekstraktu, peptidais ir hialurono rügstimi. Visiems odos tipams.
                            Šio hialurono rūgšties serumo funkcija - prisidėti prie kasdienio Jūsų odos grąžinimo
                            ritualo.
                        </h6>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Produkto sudėtyje yra drėkiklių, kurie gali palaikyti odos drėgmės balansą. Veido
                            serumas praturtintas odą puoselėjančiais ingredientais, tokiais kaip placentos ekstraktas
                            bei peptidai, todėl puikiai tinka visų tipų odos priežiūrai.
                        </h6>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Aktyvieji ingredientai</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Placentos ekstraktas, peptidai, hialurono rūgštis.
                        </h6>
                        <h4 className={'font-weight-light mt-5 mb-3'}>Naudojimas</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Ryte ir (ar) vakare tepti švarią veido, kaklo bei dekoltė odą. Švelniai įmasažuoti ir
                            palaukti, kol įsigers. Po to rekomenduojama naudoti veido krema ar emulsija.
                        </h6>
                    </div>
                    <div className={'col-6'}>
                        <h3 className="font-weight-light mb-2">Serumas su egzosomomis</h3>

                        <h4 className={'font-weight-light mt-5 mb-3'}>Apie Egzosomų serumą</h4>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Veido serumas su goji uogų ir aloe vera egzosomomis. Žodis „egzosomos“ šiuo metu skamba visų biomedicinos mokslininkų lūpose, nes tai itin svarbus ir susidomėjimo vertas objektas įvairiose sveikatos srityse. Nors egzosomų egzistavimas pirmą kartą buvo nustatytas ląstelėse dar 1983 metais, tačiau tik dabar mokslininkams atsiskleidžia jų neribotos ir milžiniškos taikymo galimybės. Egzosomos intensyviai tyrinėjamos įvairiose srityse: Nuo diagnostikos, terapijos iki vėžio prevencijos.
                            Estetikos srityje produktai su egzosomomis kelia ne mažesnį susidomėjimą dėl pastebimų regeneracijos savybių bei priešuždegiminio poveikio. Tokius produktus geba sukurti tik pažangiausios biomedicinos mokslų laboratorijos. Tie, kuriems tai jau pavyko, įžengė į visiškai naują erą ir neįtikėtiną galimybių pasaulį.
                        </h6>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Egzosomos yra nano dydžio pūslelės, kurios natūraliai susidaro ir gali būti gaunamos iš gyvūnų, žmonių ar net augalų. Būdamos uždaros lipidinės pūstelės, jos atlieka lemiamą vaidmenį ląstelių komunikacijoje. Veikdamos kaip pasiuntiniai, jos perneša molekules tarp ląstelių, taip veikdamos esminius biologinius procesus. Susidūrusios su ląstelėmis, kurios turi būti “suremontuotos”, egzosomos inicijuoja atstatymo procesus.
                        </h6>

                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Egzosomose yra įvairių bioaktyvių molekulių, tokių kaip baltymai, riebalai ir nukleorūgštys, kurios atlieka svarbų vaidmenį ląstelių tarpusavio komunikacijoje ir intraląsteliniuose signalų perdavimuose.
                        </h6>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Noras gyvūninės bei žmogaus kilmės egzosomos dar tik tyrinėjamos, tačiau ne mažesnį potencialą turinčios augalinės egzosomos kai kurių pažangiausių pasaulio biomedicinos mokslų laboratorijų buvo integruotos į kosmetinius serumus, kremus ir kitus odos priežiūros produktus. Augalinių egzosomų gebėjimas perduoti bioaktyvias medžiagas odai tiesiogiai veikia odos sveikatą bei išvaizdą. Ši sritis verčia visiškai naują lapą grožio industrijoje.
                        </h6>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Šiuo metu ne tik Lietuvoje, bet ir Pabaltyje serumas “Morning Dew” yra vienintelis produktas savo sudėtyje turintis augalines egzosomas. Pagrindinės šio produkto savybės yra
                            odos atjauninimas, t.y. elastingumo ir stangrumo atkūrimas, priešuždegiminės savybės, odoje esančių žaizdelių bei saulės nudegimų gijimo paspartinimas, odos drėkinimas.
                            Šio serumo dėka pastebimai atstatoma odos struktūra, gyvybingumas, energija bei odos sveikata.
                        </h6>
                        <h6 className="font-weight-light text-justify"
                            style={{"line-height":"1.6"}}>
                            Išgaunant egzosomas itin svarbu sukurti standartizuotas gamybos procedūras ir kokybės kontrolės priemones, kad būtų užtikrintas jų saugumas, veiksmingumas ir atkuriamumas. Būtent taip yra gaminamas odos priežiūros serumas “Morning Dew” medicinos mokslų laboratorije “CMB medicina”.
                        </h6>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(SerumComposition)