import React from "react";
import {getUrls} from "./Config";
import axios from "axios";

class PasswordReset extends React.Component{
    constructor(props){
        super(props);
        this.state={
            email: null,
            error: false,
            errorMsg: null,
            message: null,
            success: false,
            inProgress: false,
            urls: getUrls(),
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, () => this.validateEmail(name));
    };

    validateEmail = (email) => {
        if (!email){
            return false;
        }

        let required = ['@', '.']

        for (let i = 0; i < required.length; i++) {
            let valid = email.includes(required[i])

            if(!valid){
                return false;
            }
        }
        return true;
    };


    submit = (e) => {
        e.preventDefault();

        this.setState({
            error: null
        });

        let validation = this.validateEmail(this.state.email);

        if (!validation){
            this.setState({
                errorMsg: "Neteisingas el. pašto formatas."
            });


            return;
        }

        this.setState({
            inProgress: true,
            message: "Prašome palaukti ...",
            error: false
        });

        axios.post(this.state.urls.environment.development + this.state.urls.passwordReset, {
            email: this.state.email,
        }).then(
            (res) => {
                this.setState({
                    success: true,
                    message: "Dėkojame! Jums išsiųstas el. laiskas su tolimesniais veiksmais.",
                    inProgress: false,
                    error: false
                });
            }
        ).catch(
            (err) => {
                this.setState({
                    inProgress: false,
                    error: true,
                    message: null,
                    errorMsg: "Įvyko klaida. Mėginkite dar kartą."
                })
            }
        );
    };

    render() {
        return (
            <div className={"content"}>
                <div className="row p-4">
                    <div className="col-12">
                        <h3 className="font-weight-light mb-4">Atstatyti slaptažodį</h3>
                        <p>
                            {this.state.message}
                            <fa className={this.state.inProgress ? 'fa fa-sync fa-spin' : 'd-none'}></fa>
                        </p>
                    </div>
                </div>
                <form className={!this.state.success ? 'row p-4' : 'd-none'}>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <input type="email"
                                   className="form-control"
                                   name="email"
                                   id={"email"}
                                   placeholder="El. pašto adresas"
                                   onChange={this.handleChange}
                            />
                        </div>
                        <small className={'text-danger'}>{this.state.errorMsg}</small>
                    </div>
                    <div className="col-sm-4">
                        <button type={"submit"}
                                onClick={this.submit}
                                className="btn btn-light">Patvirtinti</button>
                    </div>
                    <div className="col-sm-4"></div>
                </form>
                <h1 className={this.state.success ? 'text-success' : 'd-none'}>
                    <fa className="fa fa-check-circle"></fa>
                </h1>

                <h1 className={this.state.error ? 'text-danger' : 'd-none'}>
                    <small className={"text-sm"}></small>
                    <fa className="fa fa-exclamation-circle"></fa>
                </h1>
            </div>
        )
    }
}

export default PasswordReset